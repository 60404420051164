<template>
  <div v-if="team">
    <div class="notification is-warning" v-if="!team.participationConfirmed">
      Currently the participation of this team is NOT yet confirmed by the organization.
    </div>

    <h4>Contact person</h4>
    <div class="field">
      <label class="label">Full name</label>
      <div class="control">
        <input class="input" type="text" placeholder="Text input" v-model="team.contactPersonName">
      </div>
    </div>
    <div class="field">
      <label class="label">Email</label>
      <div class="control">
        <input class="input" type="email" placeholder="Text input" v-model="team.contactPersonEmail">
      </div>
    </div>
    <div class="field">
      <label class="label">Phone</label>
      <div class="control">
        <input class="input" type="tel" placeholder="Text input" v-model="team.contactPersonPhone">
      </div>
    </div>
    <hr/>

    <h4>Team information</h4>
    <div class="field">
      <label class="label">Name of the team (deadline changing: {{ teamNameDeadline }})</label>
      <div class="control">
        <input v-if="new Date(teamNameDeadline) >= new Date()" class="input" type="text" placeholder="Text input" v-model="team.name">
        <input v-else class="input" type="text" placeholder="Text input" :value="team.name" disabled>
      </div>
    </div>
    <div class="field">
      <label class="label">Association of the team</label>
      <div class="control">
        <input class="input" type="text" placeholder="Text input" v-model="team.association">
      </div>
    </div>
    <div class="field">
      <label class="label">Number of players that join the whole tournament (deadline changing: {{ teamPlayerCountDeadline }})</label>
      <div class="control">
        <input v-if="new Date(teamPlayerCountDeadline) >= new Date()" class="input" type="text" placeholder="Text input" v-model="team.playerCount">
        <input v-else class="input" type="text" placeholder="Text input" :value="team.playerCount" disabled>
      </div>
    </div>
    <div class="field">
      <label class="label">How many people want to sleep in a cabin (deadline changing: {{ teamCabinSleepCountDeadline }})</label>
      <div class="control">
        <input v-if="new Date(teamPlayerCountDeadline) >= new Date()" class="input" type="text" placeholder="Text input" v-model="team.cabinSleepCount">
        <input class="input" type="text" placeholder="Text input" :value="team.cabinSleepCount" disabled>
      </div>
    </div>
    <div class="field">
      <label class="label">How many people want to sleep in the sportscenter (provided you are not intereseted or chosen by lottery for the cabins) (deadline changing: {{ teamCabinLotteryDate }})</label>
      <div class="control">
        <input v-if="new Date(teamCabinLotteryDate) >= new Date()" class="input" type="text" placeholder="Text input" v-model="team.sportCenterSleepCount">
        <input v-else class="input" type="text" placeholder="Text input" :value="team.sportCenterSleepCount" disabled>
      </div>
    </div>
    <hr/>

    <h4>Food preferences. If not filled in, you will receive meat vouchers (deadline changing: {{ teamFoodChangesDeadline }})</h4>
    <div class="field">
      <label class="label">Number of vegatarians</label>
      <div class="control">
        <input v-if="new Date(teamFoodChangesDeadline) >= new Date()" class="input" type="text" placeholder="0" v-model="team.foodVegaCount">
        <input v-else class="input" type="text" placeholder="0" :value="team.foodVegaCount" disabled>
      </div>
    </div>
    <div class="field">
      <label class="label">Number of vegans</label>
      <div class="control">
        <input v-if="new Date(teamFoodChangesDeadline) >= new Date()" class="input" type="text" placeholder="0" v-model="team.foodVeganCount">
        <input v-else class="input" type="text" placeholder="0" :value="team.foodVeganCount" disabled>
      </div>
    </div>
    <div class="field">
      <label class="label">Number requiring gluten free</label>
      <div class="control">
        <input v-if="new Date(teamFoodChangesDeadline) >= new Date()" class="input" type="text" placeholder="0" v-model="team.foodGlutenFreeCount">
        <input v-else class="input" type="text" placeholder="0" :value="team.foodGlutenFreeCount" disabled>
      </div>
    </div>
    <div class="field">
      <label class="label">Number requiring lactose free</label>
      <div class="control">
        <input v-if="new Date(teamFoodChangesDeadline) >= new Date()" class="input" type="text" placeholder="0" v-model="team.foodLactoseFreeCount">
        <input v-else class="input" type="text" placeholder="0" :value="team.foodLactoseFreeCount" disabled>
      </div>
    </div>
    <div class="field">
      <label class="label">Number requiring nut/peanut free</label>
      <div class="control">
        <input v-if="new Date(teamFoodChangesDeadline) >= new Date()" class="input" type="text" placeholder="0" v-model="team.foodNutAndPindaFreeCount">
        <input v-else class="input" type="text" placeholder="0" v-model="team.foodNutAndPindaFreeCount" disabled>
      </div>
    </div>
    <div class="field">
      <label class="label">Food related comments</label>
      <div class="control">
        <textarea v-if="new Date(teamFoodChangesDeadline) >= new Date()" class="textarea" placeholder="Text input" v-model="team.foodComments"></textarea>
        <textarea v-else class="textarea" placeholder="Text input" v-model="team.foodComments" disabled></textarea>
      </div>
    </div>
    <hr/>

    <h4>Other</h4>
    <div class="field">
      <label class="label">Other comments</label>
      <div class="control">
        <textarea class="textarea" placeholder="Text input" v-model="team.otherComments"></textarea>
      </div>
    </div>
    <hr/>

    <h4>Privacy</h4>
    <div class="field">
      <Checkbox v-model="team.privacyAdministrativeUpdates">
        Sent me status updates when something has changed regarding my sign-up. (e.g. when payments are received)
      </Checkbox>
      <br>
      <Checkbox v-model="team.privacySharePhoneForCabin">
        Share my phone number with other teams that are sleeping in the same cabin.
      </Checkbox>
      <br>
      <Checkbox v-model="team.privacyInviteNextYear">
        Sent me an invite next year for the new tournament.
      </Checkbox>
    </div>
    <hr/>

    <h4>Payments</h4>
    The total costs for your team will are <span :data-tooltip="totalCostsExplained">€ {{ totalCosts }}</span>. This is <span v-if="team.cabinAssignedCount > 0">incl.</span>
    <span v-else>excl.</span> cabin costs (excl. deposit).
    <div class="field">
      <label class="label">Initial (minimal € {{ minimalInitialPayment }})</label>
      <div class="control">
        <input class="input" type="text" placeholder="0" :value="team.payment1" disabled>
      </div>
    </div>
    <div class="field">
      <label class="label">Residual before starting the tournament (<span :data-tooltip="residualCostsExplained">€ {{ residualPayment }}</span>
        <span v-if="team.cabinAssignedCount > 0"> incl. cabin costs and deposit</span>
        <span v-else-if="team.cabinSleepCount > 0"> excl. cabin costs and deposit</span>
        )</label>
      <div class="control">
        <input class="input" type="text" placeholder="0" :value="team.payment2" disabled>
      </div>
    </div>
    <div class="field">
      <label class="label">Correction</label>
      <div class="control">
        <input class="input" type="text" placeholder="0" :value="team.payment3" disabled>
      </div>
    </div>
    <div class="field">
      <label class="label">Returend e.g. cabin deposits (expected € {{expectedReturn}})</label>
      <div class="control">
        <input class="input" type="text" placeholder="0" :value="team.payment4" disabled>
      </div>
    </div>
    <button
        class="button is-success is-small"
        @click="emit('save')"
    >
        Save
    </button>
  </div>
</template>

<script setup>
import Checkbox from '../generic/Checkbox.vue';

import { computed, ref, watch } from '@vue/runtime-core'

import { useStoreIsstt } from '../../stores/storeIsstt';
const issttStore = useStoreIsstt()

const props = defineProps({
  team: Object
})
const emit = defineEmits(['save'])

const costsPerPerson = computed(() => parseFloat(issttStore.variables.find(v => v.name === 'entranceFee')?.value) || 0)
const amountPerson = computed(() => parseFloat(props.team.playerCount) || 0)
const costsCabin = computed(() => parseFloat(issttStore.variables.find(v => v.name === 'CabinCosts')?.value) || 0)
const amountCabin = computed(() => parseFloat(props.team.cabinAssignedCount) || 0)
const cabinDeposit = computed(() => parseFloat(issttStore.variables.find(v => v.name === 'CabinDeposit')?.value) || 0)
const initialPaid = computed(() => parseFloat(props.team.payment1) || 0)
const residualPaid = computed(() => parseFloat(props.team.payment2) || 0)
const correctionPaid = computed(() => parseFloat(props.team.payment3) || 0)
const teamNameDeadline = computed(() => (issttStore.variables.find(v => v.name === 'NameChangeDeadline')?.value) || 'Unknown')
const teamPlayerCountDeadline = computed(() => (issttStore.variables.find(v => v.name === 'RegistrationDeadlineSignUps')?.value) || 'Unknown')
const teamCabinSleepCountDeadline = computed(() => (issttStore.variables.find(v => v.name === 'RegistrationDeadlineCabins')?.value) || 'Unknown')
const teamFoodChangesDeadline = computed(() => (issttStore.variables.find(v => v.name === 'FoodChangeDeadline')?.value) || 'Unknown')
const teamCabinLotteryDate = computed(() => (issttStore.variables.find(v => v.name === 'CabinLotteryDate')?.value) || 'Unknown')


const minimalInitialPayment = computed(() => parseFloat(issttStore.variables.find(v => v.name === 'minimumEntranceFee')?.value) || 0)
const totalCosts = computed(() => {
  return props.team.totalCosts(costsPerPerson.value, costsCabin.value)
})
const residualPayment = computed(() => {
  return props.team.residualCosts(costsPerPerson.value, costsCabin.value, cabinDeposit.value)
})
const expectedReturn = computed(() => {
  const result = totalCosts.value - initialPaid.value - residualPaid.value - correctionPaid.value
  if (result < 0) return Math.abs(result)
  return 0
})

const peopleExplained = computed(() => `entrance fee (${costsPerPerson.value}) * people (${amountPerson.value})` )
const cabinExplained = computed(() => `cabin fee (${costsCabin.value}) * cabin spots assigned (${amountCabin.value}) * nights (2)` )

const totalCostsExplained = computed(() => {
  if (props.team.cabinAssignedCount > 0) return `${peopleExplained.value} + ${cabinExplained.value}`
  return peopleExplained.value
})
const residualCostsExplained = computed(() => {
  if (props.team.cabinAssignedCount > 0) return `total (${totalCosts.value}) - initial payment (${initialPaid.value}) + cabin deposit (${cabinDeposit.value})`
  return `total (${totalCosts.value}) - initial payment (${initialPaid.value})`
})
</script>

<style scoped>
</style>