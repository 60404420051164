import { defineStore } from 'pinia'
import { db } from '@/firebase'
import { collection, updateDoc, doc, addDoc, onSnapshot } from 'firebase/firestore'
import { v4 as uuidv4 } from 'uuid';
import { useRoute } from 'vue-router';
import { Team } from '../classes/Team';
import { useStoreGames } from '@/stores/storeGames'
import { useStoreScores } from '@/stores/storeScores'
import { useStoreIsstt } from './storeIsstt';

const teamsRef = collection(db, 'teams')
let unsub = undefined

export const useStoreTeams = defineStore('storeTeams', {
  state: () => {
    return {
      currentTeamsUid: undefined,
      teams: [],
    }
  },
  actions: {
    subscribe(ref) {
      this.unsubscribe()
      const unsub = onSnapshot(doc(teamsRef, ref), (doc) => {
        this.currentTeamsUid = doc.id
        const updatedTeams = doc.data().teams ?? []
        this.teams = updatedTeams.map(t => {
          return Object.assign(new Team(), t)
        })
      });
    },
    unsubscribe() {
      if (unsub !== undefined) unsub();
    },
    createTeamsObject() {
      addDoc(teamsRef, JSON.parse(JSON.stringify({
        teams: this.teams
      })))
    },
    saveTeams() {
      if (this.currentTeamsUid) {
        updateDoc(doc(teamsRef, this.currentTeamsUid), JSON.parse(JSON.stringify({
          teams: this.teams
        })))
        .then(() => alert('Saved'))
        .catch((error) => alert(error.message))
      } else {
        this.createTeamsObject()
      }
    },
    addTeam(amount = 1) {
      if (!this.teams) this.teams = []
      const result = []
      for (let i = 0; i < amount; i++) {
        const newTeam = new Team()
        result.push(newTeam)
        this.teams.push(newTeam);
      }
      return result;
    },
    deleteTeam(id) {
      const team = this.teams.find(t => t.id === id)
      if (team.participationConfirmed) {
        alert("Cannot delete a team that is actively participating the tournament.")
        return
      }
      this.teams = this.teams.filter(t => t.id !== id)
      this.saveTeams()
    },
    replaceVariables(str) {
      const gameStore = useStoreGames()
      const scoreStore  = useStoreScores()
      const issttStore = useStoreIsstt()
      const minimalPayment = issttStore.variables.find(it => it.name === 'minimumEntranceFee')?.value
      let result = `${str}`
      // replace variables for teams
      const teamRegex = /\[teams\]\(([0-9\,alinkpaidrank\-]+)\)/g
      const matches = [...result.matchAll(teamRegex)]
      matches.forEach((match, index) => {
        const params = match[1].split(',')
        let select = 'all'
        let link = false
        if (Number.isInteger(Number(params.first()))) {
          select = Number(params.first())
        }
        link = (params.includes('link'))
        if (select === 'all') {
          let teams = this.teams.filter(t => t.name !== undefined && t.name !== "").sortBy("name")
          if (params.includes('paid')) teams = teams.filter(t => parseFloat(t.payment1) >= minimalPayment)
          if (params.includes('-paid')) teams = teams.filter(t => isNaN(parseFloat(t.payment1)) || parseFloat(t.payment1) < minimalPayment )
          if (params.includes('rank')) {
            // only do this if all scores are available
            const unplayed = scoreStore.scores.filter(s => s.home === undefined || s.away === undefined)
            const rounds = gameStore.rounds.length
            if (unplayed.length === 0 && rounds > 0) {
              teams
                .filter(t => t.participationConfirmed === true)
                .forEach((t,i) => {
                const placement = t.tags[t.tags.length -1]
                const match = gameStore.matches
                  .find(m => m.round === rounds && (m.home === placement || m.away === placement))
                const score = scoreStore.scores.find(s => s.match === match.id)
                t.rank = match.poule * 2
                const home = match.home === placement
                if (home) {
                  if (score.home > score.away) {
                    t.rank -= 2
                  } else {
                    t.rank -= 1
                  }
                } else {
                  if (score.home > score.away) {
                    t.rank -= 1
                  } else {
                    t.rank -= 2
                  }
                }
              })
              teams = teams.sortBy('rank')
            }
          }
          let lis = ''
          if (link) {
            teams.forEach(team => lis += `<li><a href="/${useRoute().params.isstt}/teams/${team.id}">${team.name}</a></li>`)
          } else {
            teams.forEach(team => lis += `<li>${team.rank !== undefined ? team.rank+1 : ''}${team.rank !== undefined ? ' - ' : ''}${team.name}</li>`)
          }
          result = result.replace(match[0], `<ul>${lis}</ul>`)
        } else {
          const team = this.teams[select - 1]
          let temp = ''
          if (link) {
            temp += `<span><a href="/${useRoute().params.isstt}/teams/${team?.id}">${team?.name}</a></span>`
          } else {
            temp += `<span>${team?.name}</span>`
          }
          result = result.replace(match[0], temp)
        }
      })
      return result
    },
    byPlacement(str) {
      return this.teams.find(t => t.tags.includes(str))
    }
  }
})
