<template>
  <li>
    <NavigationLink :url="props.url + (props.default ? props.default : '')" :text="text" />
    <ul v-if="currentPath.startsWith(`/${route.params.isstt}${url}`)">
      <slot></slot>
    </ul>
  </li>
</template>

<script setup>
import NavigationLink from "@/components/navigation/NavigationLink.vue";

import { computed } from "vue";
import { useRoute } from 'vue-router';
const route = useRoute()
const currentPath = computed(() => {
  return route.path
})
const props = defineProps(['url', 'text', 'default'])
</script>