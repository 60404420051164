<template>
  <NavigationCombined @loaded="emit('loaded')">
      <ButtonGroup v-if="issttStore.currentEdition">
        <Button v-for="edition in issttStore.editions" :key="edition.id"
          :isSelected="edition.id === issttStore.currentEdition.id"
          :color="edition.id === issttStore.currentEdition.id ? 'info' : ''"
          @click="issttStore.changeEdition(edition.id)"
        >
          {{ edition.name }}
        </Button>
      </ButtonGroup>
      <p class="menu-label">
        <RouterLink to="/" :class="{'is-active': currentPath == ``}">Home</RouterLink>
      </p>
      <template v-for="(page) in issttStore.pages" :key="page">
        <p class="menu-label" @click.stop="togglePage(page.title)" @mousedown.prevent="">
          {{ page.title }}
          <i class="fas fa-caret-up" v-if="pageToggles[page.title]"></i>
          <i class="fas fa-caret-down" v-else></i>
        </p>
        <ul class="menu-list" v-if="pageToggles[page.title]">
          <template v-for="(subPage) in page.content" :key="subPage">
            <NavigationLink :url="`/info/${page.title}/${subPage.title}`" :text="subPage.title" />
          </template>
        </ul>
      </template>
      <template v-if="authStore.isAdmin && authStore.adminView">
        <p class="menu-label" @click.stop="togglePage('Admin')" @mousedown.prevent="">
          Admin
          <i class="fas fa-caret-up" v-if="pageToggles['Admin']"></i>
          <i class="fas fa-caret-down" v-else></i>
        </p>
        <ul class="menu-list" v-if="pageToggles['Admin']">
          <NavigationLink :url="`/admin/Pages`" text="Pages" />
          <NavigationLink :url="`/admin/Variables`" text="Variables" />
          <NavigationLink :url="`/admin/Settings`" text="Settings" />
          <NavigationLinkGroup :url="`/admin/Teams`" text="Teams">
            <NavigationLink :url="`/admin/Teams/Administration`" text="Administration" />
          </NavigationLinkGroup>
          <NavigationLinkGroup :url="`/admin/Schedule`" text="Tournament (before)" default="/Poules" >
            <NavigationLink :url="`/admin/Schedule/Poules`" text="Poules" />
            <NavigationLink :url="`/admin/Schedule/Matches`" text="Matches" />
            <NavigationLink :url="`/admin/Schedule/TimeSlots`" text="TimeSlots" />
            <NavigationLink :url="`/admin/Schedule/ScheduleMatches`" text="Schedule Matches" />
          </NavigationLinkGroup>
          <NavigationLinkGroup :url="`/admin/Management`" text="Tournament (during)" default="/Scores" >
            <NavigationLink :url="`/admin/Management/Scores`" text="Scores" />
            <NavigationLink :url="`/admin/Management/Poules`" text="Poules" />
            <NavigationLink :url="`/admin/Management/Print`" text="Print" />
          </NavigationLinkGroup>
        </ul>
      </template>
      <template v-if="authStore.user">
        <p class="menu-label">
          <NavigationLink :url="`/account`" text="Account" />
        </p>
        <p class="menu-label">
          <a @click="authStore.logoutUser()">
            Logout
          </a>
        </p>
      </template>
      <p class="menu-label" v-else>
        <NavigationLink :url="`/auth`" text="Login" />
      </p>
    </NavigationCombined>
</template>

<script setup>
import NavigationCombined from "@/components/navigation/NavigationCombined.vue";
import NavigationLink from "@/components/navigation/NavigationLink.vue";
import NavigationLinkGroup from "@/components/navigation/NavigationLinkGroup.vue";
import ButtonGroup from '../generic/ButtonGroup.vue';
import Button from '../generic/Button.vue';

import { computed, ref } from "vue";
import { useRoute } from 'vue-router';

import { useStoreAuth } from "@/stores/storeAuth";
import { useStoreIsstt } from "@/stores/storeIsstt";

const authStore = useStoreAuth();
const issttStore = useStoreIsstt();

const emit = defineEmits(['loaded']);

const route = useRoute()
const currentPath = computed(() => {
  return route.path
})

const pageToggles = ref({})
function togglePage(pageName) {
  if (pageToggles.value[pageName] === true) {
    pageToggles.value[pageName] = false
  } else {
    pageToggles.value[pageName] = true
  }
}
</script>

<style scoped>
.menu-label {
  cursor: pointer;
}
</style>