<template>
  <div
      class="navbar-menu is-hidden-desktop pl-4"
      :class="{'is-active' : open}"
      @click.stop="emit('close')"
    >
      <slot></slot>
    </div>
</template>

<script setup>
import { ref } from "@vue/runtime-core";

const props = defineProps({
  open: Boolean
})

const emit = defineEmits(['close'])
</script>