import { ref } from "vue";
import { defineStore } from 'pinia'
import Toast from "./Toast"

export const useStoreToast = defineStore('storeToast', () => {
  const toasts = ref<Toast[]>([]);
  const defaultDisplayTime = ref(3000)

  let timeout: NodeJS.Timeout | undefined = undefined
  function add(toast: Toast) {
    toasts.value.push(toast)
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(cycle, defaultDisplayTime.value)
  }

  function cycle() {
    toasts.value.shift()
    if (timeout) clearTimeout(timeout);
    if (toasts.value.length > 0) timeout = setTimeout(cycle, defaultDisplayTime.value)
  }

  function success(msg: string) {
    add(new Toast(msg, 'success', true))
  }

  function warning(msg: string) {
    add(new Toast(msg, 'warning', true))
  }

  function error(msg: string) {
    add(new Toast(msg, 'error', true))
  }

  return {
    toasts,
    success,
    warning,
    error,
    cycle,
  }
})