import { computed, ref } from "vue";
import { defineStore } from 'pinia'
import { createUserWithEmailAndPassword, signOut, signInWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail, getAuth } from "firebase/auth";
import { auth, db } from '../firebase';
import { collection, doc, onSnapshot } from 'firebase/firestore'
import { useStoreToast } from './toast/storeToast';
import { useStoreIsstt } from './storeIsstt';
import router from '../router';

class User {
  id: string
  email: string | null
}

export const useStoreAuth = defineStore('storeAuth', () => {
  const toastStore = useStoreToast();
  const issttStore = useStoreIsstt();

  const userRef = collection(db, 'users')
  let unsub = undefined;

  const user = ref<User | undefined>(undefined);
  const roles = ref<string[]>([]);
  const adminView = ref(true);

  function init() {
    onAuthStateChanged(auth, (authUser) => {
      const path = issttStore.currentEdition?.path || 'student'
      if (authUser) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        user.value = {
          id: authUser.uid,
          email: authUser.email
        }
        this.subscribe()
        this.router.push('/'+path)
      } else {
        // User is signed out
        // note: also tiggers on normal page things
        user.value = undefined
        this.roles = []
        this.router.push('/'+path)
      }
    });
  }

  function subscribe() {
    this.unsubscribe();
    this.unsub = onSnapshot(doc(userRef, user.value?.id), (doc) => {
      this.roles = doc.data()?.roles ?? []
    });
  }

  function unsubscribe() {
    if (unsub !== undefined) this.unsub();
  }

  function registerUser(userName, password) {
    createUserWithEmailAndPassword(auth, userName, password)
      .then((userCredential) => {
        user.value = {
          id: userCredential.user.uid,
          email: userCredential.user.email
        }
        toastStore.success("Account created 🎉")
      })
      .catch((error) => {
        toastStore.error(error.message)
      });
  }

  function logoutUser() {
    signOut(auth)
      .then(() => {
        toastStore.success("Logged out 👋")
      }).catch((error) => {
        toastStore.error(error.message)
      });
  }

  function loginUser(userName, password) {
    signInWithEmailAndPassword(auth, userName, password)
      .then((userCredential) => {
        toastStore.success("Logged in 👋")
      })
      .catch((error) => {
        toastStore.error(error.message)
      });
  }

  function resetPassword(email) {
    sendPasswordResetEmail(getAuth(), email)
    .then(() => {
      toastStore.success("Email has been sent 📬")
    })
    .catch((error) => {
      toastStore.error(error.message)
    });
  }

  const isAdmin = computed(() => {
      return roles.value.includes('admin')
  })

  const isLoggedIn = computed(() => {
      return user.value !== undefined
  })

  return {
    user,
    roles,
    adminView,

    init,
    subscribe,
    unsubscribe,

    registerUser,
    loginUser,
    logoutUser,
    resetPassword,

    isAdmin,
    isLoggedIn,
  }
})
