<template>
    <div v-if="visible || (authStore.isAdmin && authStore.adminView)"
    class="card"
  >
    <header class="card-header">
      <p class="card-header-title">
        <p><DynamicElement :input="titleData"></DynamicElement></p>
        <input
          v-if="edit"
          type="text"
          class="input is-small"
          v-model="titleData"
        >
      </p>
      <p v-if="dateData" class="p-3">
        {{ moment(String(new Date(dateData).toISOString())).format('MM/DD/YYYY hh:mm') }}
      </p>
      <div
          v-if="authStore.isAdmin && authStore.adminView"
          class="buttons has-addons"
      >
        <button
          class="card-header-icon"
          @click="locked = !locked"
        >
          <span v-if="locked" class="icon">
            <i class="fas fa-lock"></i>
          </span>
          <span v-else class="icon">
            <i class="fas fa-unlock"></i>
          </span>
        </button>
        <button
          v-if="!hideMove"
          class="card-header-icon"
          @click="$emit('moveUp')"
          :disabled="locked"
          :class="{'disabled':locked}"
        >
          <span class="icon">
            <i class="fas fa-chevron-up"></i>
          </span>
        </button>
        <button
          v-if="!hideMove"
          class="card-header-icon"
          @click="$emit('moveDown')"
          :disabled="locked"
          :class="{'disabled':locked}"
        >
          <span class="icon">
            <i class="fas fa-chevron-down"></i>
          </span>
        </button>
        <button
          class="card-header-icon"
          @click="edit = !edit"
          :disabled="locked"
          :class="{'disabled':locked}"
        >
          <span class="icon">
            <i class="fas fa-pen"></i>
          </span>
        </button>
        <button
          v-if="!hideCopy"
          class="card-header-icon"
          @click="$emit('copy')"
          :disabled="locked"
          :class="{'disabled':locked}"
        >
          <span class="icon">
            <i class="fas fa-copy"></i>
          </span>
        </button>
        <button
          v-if="!hideVisibleToggle"
          class="card-header-icon"
          @click="visibleData = !visibleData"
          :disabled="locked"
          :class="{'disabled':locked}"
        >
          <span class="icon">
            <i v-if="visibleData" class="fas fa-eye"></i>
            <i v-else class="fas fa-eye-slash"></i>
          </span>
        </button>
        <button
          class="card-header-icon"
          @click="$emit('delete')"
          :disabled="locked"
          :class="{'disabled':locked}"
        >
          <span class="icon">
            <i class="fas fa-trash"></i>
          </span>
        </button>
      </div>
    </header>
    <div class="card-content">
      <div class="content">
        <div><DynamicElement :input="contentData"></DynamicElement></div>
        <textarea
          v-if="edit"
          type="textarea"
          class="textarea is-small"
          v-model="contentData"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script setup>
import moment from 'moment'
import DynamicElement from './DynamicElement.vue'

import { useStoreAuth } from '@/stores/storeAuth'
import { ref, watch, onMounted } from 'vue'
const authStore = useStoreAuth()

const props = defineProps({
    id: String,
    title: String,
    content: String,
    date: Number,
    visible: Boolean,
    hideMove: Boolean,
    hideCopy: Boolean,
    hideVisibleToggle: Boolean,
})
const titleData = ref(props.title)
const contentData = ref(props.content)
const visibleData = ref(props.visible)
const dateData = ref(props.date)
const locked = ref(true)

const emit = defineEmits(['update:title', 'update:content', 'update:visible', 'copy'])

const edit = ref(false)

watch(
  () => titleData.value,
  (current, old) => {
    emit('update:title', current)
  }
)
watch(
  () => contentData.value,
  (current, old) => {
    emit('update:content', current)
  }
)
watch(
  () => visibleData.value,
  (current, old) => {
    emit('update:visible', current)
  }
)

onMounted(() => {
    edit.value = false
})
</script>

<style scoped>
.disabled {
  color: lightgray;
}
</style>