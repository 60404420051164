<template>
  <RouterLink :to="`/${route.params.isstt}${url}`" :class="{'is-active': currentPath.startsWith(`/${route.params.isstt}${url}`)}">{{ text }}</RouterLink>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from 'vue-router';
const route = useRoute()
const currentPath = computed(() => {
  return route.path
})
const props = defineProps(['url', 'text'])
</script>