<template>
  <div v-if="key === 'div'">
    <DynamicSubElement v-for="i in input[key]" :key="i" :input="i"></DynamicSubElement>
  </div>
  <span v-else-if="key === 'span'">
    <DynamicSubElement v-for="i in input[key]" :key="i" :input="i"></DynamicSubElement>
  </span>
  <RouterLink
    v-else-if="
      key === 'a' &&
      attributes.href &&
      !(attributes.href.startsWith('http') || attributes.href.startsWith('mailto'))
    "
    :to="attributes.href"
  >
    <DynamicSubElement v-for="i in input[key]" :key="i" :input="i"></DynamicSubElement>
  </RouterLink>
  <a v-else-if="key === 'a'" :href="attributes.href">
    <DynamicSubElement v-for="i in input[key]" :key="i" :input="i"></DynamicSubElement>
  </a>
  <strong v-else-if="key === 'strong'">
    <DynamicSubElement v-for="i in input[key]" :key="i" :input="i"></DynamicSubElement>
  </strong>
  <ul v-else-if="key === 'ul'">
    <DynamicSubElement v-for="i in input[key]" :key="i" :input="i"></DynamicSubElement>
  </ul>
  <li v-else-if="key === 'li'">
    <DynamicSubElement v-for="i in input[key]" :key="i" :input="i"></DynamicSubElement>
  </li>
  <template v-else-if="key === 'img'">
    <img :src="attributes.src" :style="attributes.style" />
    {{ input[key]?.first()?.["#text"] }}
  </template>
  <template v-else-if="key === 'br'">
    <br />
  </template>

  <template v-else-if="key === '#text'">{{ input[key] }}</template>
  <template v-else>{{ input }}</template>
</template>

<script setup>
import { computed, onMounted, reactive } from "vue";

const props = defineProps({
  input: Object,
});
const attributes = computed(() => {
  const result = {};
  Object.entries(props.input[":@"]).forEach(([a, b]) => {
    result[a] = b;
  });

  return result;
});
const key = computed(() => {
  return Object.keys(props.input)
    .filter((f) => f !== ":@")
    .first();
});
</script>
