import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export const localeStringRegex = /([\d]+)\/([\d]+)\/([\d]+), ([\d]+):([\d]+):([\d]+)/
export const dateTimeInputTemplate = "$3-$2-$1T$4:$5"

export class TimeSlotForm {
  constructor(start, end) {
    this.id = uuidv4(),
      this.startDateTimeInput = (start ?? new Date()).toLocaleString().replace(localeStringRegex, dateTimeInputTemplate),
      this.endDateTimeInput = moment((end ?? start ?? new Date())).add(1, 'd').toDate().toLocaleString().replace(localeStringRegex, dateTimeInputTemplate),
      this.matchDuration = 20,
      this.betweenMatchDuration = 5,
      this.fields = 8;
  }
}
