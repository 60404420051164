import { v4 as uuidv4 } from 'uuid';

export class Page {
  id: string
  title: string
  content?: Page[] | string
  visible: boolean

  constructor() {
    this.id = uuidv4(),
    this.title = ""
    this.visible = true
  }

  contentAsPages(): Page[] {
    if (this.content as Page[]) {
      return this.content as Page[]
    }
    return []
  }

  addSubPage(subPage: Page = new Page()) {
    if (this.content === undefined) this.content = []
    if (this.content as Page[]) {
      (this.content as Page[]).push(subPage);
    }
  }

  addInfoCard() {
    if (this.content === undefined) this.content = []
    const card = new Page()
    card.content = ""
    if (this.content as Page[]) {
      (this.content as Page[]).push(card);
    }
  }
}