<template>
  <div v-if="isMobile">
    <table class="table is-bordered is-striped is-narrow small">
      <tbody>
        <template v-for="match in teamMatches" :key="match.id">
          <tr>
            <td>{{ match.timeSlot.start?.pretty() }}</td>
            <td>
              <p class="help">Round</p>
              {{ match.round }}
            </td>
            <td>
              <p class="help">Field</p>
              {{ match.field }}
            </td>
          </tr>
          <tr>
            <td class="last">
              <p class="help">Home</p>
              <RouterLink :to="`/teams/${teamStore.byPlacement(match.home)?.id}`">{{
                teamStore.byPlacement(match.home)?.name
              }}</RouterLink>
            </td>
            <td class="last">
              <p class="help">Away</p>
              <RouterLink :to="`/teams/${teamStore.byPlacement(match.away)?.id}`">{{
                teamStore.byPlacement(match.away)?.name
              }}</RouterLink>
            </td>
            <td class="last">
              <p class="help">Ref</p>
              <RouterLink :to="`/teams/${teamStore.byPlacement(match.ref)?.id}`">{{
                teamStore.byPlacement(match.ref)?.name
              }}</RouterLink>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
  <div v-else>
    <table class="table is-bordered is-striped">
      <thead>
        <tr>
          <th>Start</th>
          <th>Round</th>
          <th>Field</th>
          <th>Home</th>
          <th>Away</th>
          <th>Referee</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="match in teamMatches" :key="match.id">
          <td>{{ match.timeSlot?.start?.pretty() || 'Currently unknown' }}</td>
          <td>{{ match.round }}</td>
          <td>{{ match.field || 'Currently unknown' }}</td>
          <td>
            <RouterLink :to="`/teams/${teamStore.byPlacement(match.home)?.id}`">{{
              teamStore.byPlacement(match.home)?.name
            }}</RouterLink>
          </td>
          <td>
            <RouterLink :to="`/teams/${teamStore.byPlacement(match.away)?.id}`">{{
              teamStore.byPlacement(match.away)?.name
            }}</RouterLink>
          </td>
          <td>
            <RouterLink :to="`/teams/${teamStore.byPlacement(match.ref)?.id}`">{{
              teamStore.byPlacement(match.ref)?.name
            }}</RouterLink>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { useStoreGames } from "@/stores/storeGames";
import { useStoreTeams } from "@/stores/storeTeams";
import { computed } from "@vue/runtime-core";

const gameStore = useStoreGames();
const teamStore = useStoreTeams();

const props = defineProps({
  team: Object,
});

const teamMatches = computed(() => {
  return gameStore.matches
    .filter((m) => {
      return (
        props.team.tags.includes(m.home) ||
        props.team.tags.includes(m.away) ||
        props.team.tags.includes(m.ref)
      );
    })
    .map((m) => {
      const ts = { timeSlot: gameStore.timeSlots.find((ts) => ts.id === m.timeSlot) };
      return Object.assign({}, m, ts);
    })
    .sortBy("timeSlot.start");
});

const isMobile = computed(() => {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
  } else {
    return false;
  }
});
</script>

<style scoped>
.help {
  font-size: 0.5rem;
}
.small {
  font-size: 0.75rem;
}
.last {
  border-bottom: 1px solid black;
}
</style>
