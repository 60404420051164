<template>
  <div
      v-for="round in relevantPoules"
      :key="round.id"
      class="round"
    >
      <div v-if="isMobile">
        <table
          v-for="(poule, index) in round"
          :key="index"
          class="table is-bordered is-striped is-narrow small"
        >
          <tbody>
            <template
                v-for="(placement, index) in sorted(poule)"
                :key="placement.id">
              <tr>
                <td>{{ index + 1 }}</td>
                <td colspan="4"><RouterLink :to="`/teams/${teamStore.byPlacement(placement.id)?.id}`">{{ team(placement.id)?.name }}</RouterLink></td>
                <td colspan="2"><p class="help">Played</p></td>
                <td>{{ gameStore.placementsScored[placement.id].matches.filter((m) => m.played).length }}/{{ gameStore.placementsScored[placement.id].matches.length }}</td>
              </tr>
              <tr>
                <td class="last"><p class="help">W</p>{{ gameStore.placementsScored[placement.id].wins }}</td>
                <td class="last"><p class="help">D</p>{{ gameStore.placementsScored[placement.id].draws }}</td>
                <td class="last"><p class="help">L</p>{{ gameStore.placementsScored[placement.id].loses }}</td>
                <td class="last"><p class="help">P</p>{{ gameStore.placementsScored[placement.id].points }}</td>
                <td class="last"><p class="help">GT</p>{{ gameStore.placementsScored[placement.id].goalTotal }}</td>
                <td class="last"><p class="help">GM</p>{{ gameStore.placementsScored[placement.id].selfTotal }}</td>
                <td class="last"><p class="help">GA</p>{{ gameStore.placementsScored[placement.id].otherTotal }}</td>
                <td class="last"><p class="help">Next</p>{{ gameStore.placementsScored[placement.id].to?.name }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div v-else>
        <table
          v-for="(poule, index) in round"
          :key="index"
          class="table is-bordered is-striped"
        >
          <thead>
            <tr>
              <th>Team</th>
              <th>Played</th>
              <th>Won</th>
              <th>Draw</th>
              <th>Lost</th>
              <th>Points</th>
              <th>GT</th>
              <th>GM</th>
              <th>GA</th>
              <th>Next</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="placement in sorted(poule)"
              :key="placement.id"
            >
              <td><RouterLink :to="`/teams/${teamStore.byPlacement(placement.id)?.id}`">{{ team(placement.id)?.name }}</RouterLink></td>
              <td>{{ gameStore.placementsScored[placement.id].matches.filter((m) => m.played).length }}/{{ gameStore.placementsScored[placement.id].matches.length }}</td>
              <td>{{ gameStore.placementsScored[placement.id].wins }}</td>
              <td>{{ gameStore.placementsScored[placement.id].draws }}</td>
              <td>{{ gameStore.placementsScored[placement.id].loses }}</td>
              <td>{{ gameStore.placementsScored[placement.id].points }}</td>
              <td>{{ gameStore.placementsScored[placement.id].goalTotal }}</td>
              <td>{{ gameStore.placementsScored[placement.id].selfTotal }}</td>
              <td>{{ gameStore.placementsScored[placement.id].otherTotal }}</td>
              <td>{{ gameStore.placementsScored[placement.id].to?.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
</template>

<script setup>
import { useStoreGames } from '@/stores/storeGames'
import { useStoreTeams } from '@/stores/storeTeams'
import { useStoreScores } from '@/stores/storeScores'
import { computed } from '@vue/runtime-core'

const gameStore = useStoreGames()
const teamStore = useStoreTeams()
const scoreStore = useStoreScores()

const props = defineProps({
  team: Object
})

const team = (placementId) => {
  return teamStore.teams.find((t) => t.tags.includes(placementId))
}

const relevantPoules = computed(() => {
  return Object.values(gameStore.placementsGrouped).map(round => {
    return Object.values(round.poules)
    .filter(placements => {
      return placements.filter(p => p.id).some(it => props.team.tags.includes(it.id))
    })
  })
})

const sorted = (placements) => {
  const result = placements.map((p) => {
    const temp = Object.assign({}, p)
    temp.info = gameStore.placementsScored[p.id]
    return temp
  }).sortByDesc('info.points','info.goalTotal','info.selfTotal')
  for (let i=0; i < result.length; i++) {
    if (result[i].info.to !== [] && Array.isArray(result[i].info.to)) {
      const temp = result[i].info.to.find((t) => parseInt(t.from.split('-')[2]) === i+1)
      if (temp !== undefined) result[i].info.to = temp
    }
  }
  return result
}

const isMobile = computed (() => {
   if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
     return true
   } else {
     return false
   }
 })
</script>

<style scoped>
.help {
  font-size: 0.5rem;
}
.small {
  font-size: 0.75rem;
}
.last {
  border-bottom: 1px solid black;
}
</style>