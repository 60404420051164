<template>
  <img class="loading" v-if="loading" src="@/assets/better-loading.png" style="background-color:#112233">
  <div v-show="!loading" id="app-full-size" :style="`background-image: url('${backgroundImageUrl}')`">
    <Navigation></Navigation>
    <Floorball />
    <Tooltip />
  </div>
  <Toast/>
</template>

<script setup>
import moment from "moment";
import Navigation from "@/components/navigation/Navigation.vue";
import Floorball from "@/components/Floorball.vue";
import Tooltip from "@/components/generic/Tooltip.vue"
import Toast from "@/components/Toast.vue";
import { onMounted, watch, ref, computed } from "@vue/runtime-core";
import { useStoreIsstt } from "@/stores/storeIsstt";
import { useStoreAuth } from "@/stores/storeAuth";
import { useStoreFiles } from "@/stores/storeFiles";
import { useStoreToast } from "@/stores/toast/storeToast";
import { getDownloadURL } from "firebase/storage";

const issttStore = useStoreIsstt();
const authStore = useStoreAuth();
const fileStore = useStoreFiles();
const toastStore = useStoreToast();
const navbarLoading = ref(false)
const backgroundLoading = ref(false)

const loading = computed(() => {
  return fileStore.loading || issttStore.loading || navbarLoading.value || backgroundLoading.value
})

const backgroundImageUrl = ref('')
watch(
  () => issttStore.backgroundImageRef,
  (current, old) => {
    if (current) {
        let result
        getDownloadURL(current)
          .then((url) => {
            backgroundImageUrl.value = url
            backgroundLoading.value = false
          })
      } else {
        backgroundLoading.value = false
      }
  }
)

onMounted(() => {
  authStore.init()
  issttStore.subscribe();
  fileStore.listAll();
});

watch(
  () => issttStore.settings.find((setting) => setting.name === "backgroundColor"),
  (current, previous) => {
    if (current) {
      document.documentElement.style.setProperty(
        "--background-color",
        `${current.value}`
      );
    }
  }
);

onMounted(() => {
  Array.prototype.random = function () {
    return this[Math.floor(Math.random() * this.length)];
  };
  Array.prototype.groupBy = function (key) {
    const grouped = this.reduce((group, item) => {
      const groupKey = item[key];
      group[groupKey] = group[groupKey] ?? [];
      group[groupKey].push(item);
      return group;
    }, {});
    return grouped;
  };
  Array.prototype.sortBy = function () {
    let args = arguments // note: dont place in sort, then arguments will be a,b
    this.sort(function (a, b) {
      let result
      for(let i =0; i < args.length; i++) {
        const argument = args[i].split(".")
        const x = argument.reduce((prev, curr) => prev?.[curr], a)
        const y = argument.reduce((prev, curr) => prev?.[curr], b)
        // const y = b[argument]
        if (x !== y) {
          i = args.length
          result = x < y ? -1 : 1
        } else if (i === argument.length - 1) {
          result = 0
        }
      }
      return result
    });
    return this
  };
  Array.prototype.sortByDesc = function () {
    let args = arguments // note: dont place in sort, then arguments will be a,b
    this.sort(function (a, b) {
      let result
      for(let i =0; i < args.length; i++) {
        const argument = args[i].split(".")
        const x = argument.reduce((prev, curr) => prev?.[curr], a)
        const y = argument.reduce((prev, curr) => prev?.[curr], b)
        if (x !== y) {
          i = args.length
          result = x < y ? 1 : -1
        } else if (i === argument.length - 1) {
          result = 0
        }
      }
      return result
    });
    return this
  };
  Array.prototype.last = function () {
    return this[this.length - 1];
  };
  Array.prototype.first = function () {
    return this[0];
  };
  Array.prototype.move = function (from, to) {
    if (from < 0 || from > this.length - 1 || to < 0 || to > this.length - 1) return;
    const element = this.splice(from, 1)[0];
    this.splice(to, 0, element);
  };
  Array.prototype.unique = function (param) {
    if (param === undefined) {
      return this.filter((value, index, array) => {
        return array.indexOf(value) === index;
      })
    } else {
      return this.filter((value, index, array) => {
        return array.findIndex((i) => i[param] === value[param]) === index;
      })
    }
  }
})
onMounted(() => {
  const prettyDateTimeFormat = "MM/DD/YYYY HH:mm";
  const prettyDateFormat = "MM/DD/YYYY";
  const prettyTimeFormat = "HH:mm";
  Date.prototype.pretty = function () {
    return moment(String(this.toISOString())).format(prettyDateTimeFormat);
  };
  Date.prototype.prettyTime = function () {
    return moment(String(this.toISOString())).format(prettyTimeFormat);
  };
  Date.prototype.prettyDate = function () {
    return moment(String(this.toISOString())).format(prettyDateFormat);
  };
})

</script>

<style>
@import "bulma/css/bulma.min.css";
:root {
  --main-bg-color: rgba(255, 255, 255, 0.97);
}
.card {
  margin-bottom: 0.5rem;
  background-color: rgba(255,255,255,0.97);
}
#body {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  background: var(--background-color);
  overflow-x: scroll;
}
#app {
  height: 100%;
  width: 100%;
}
#app-full-size {
  background: var(--background-color);
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: fixed;
  background-position: center;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.loading {
  height: 100vh;
  width: 100vw;
  object-fit: contain;
}
.table {
  width: 100%;
  margin-bottom: .5rem;
}
</style>
