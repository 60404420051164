import { v4 as uuidv4 } from 'uuid';

export class Setting {
  id: string
  name: string
  value: string

  constructor(name: string) {
    this.id = uuidv4(),
    this.name = name,
    this.value = ""
  }
}