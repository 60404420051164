<template>
  <label class="checkbox">
    <input type="checkbox" v-model="model">
    <slot></slot>
  </label>
</template>

<script setup>
import { defineModel } from 'vue';
const model = defineModel({ type: Boolean })
</script>