<template>
  <button
    class="button is-small"
    :class="[
      isToggle ? toggleState ? 'is-success' : 'is-danger' : '',
      {
        'is-loading' : isLoading,
      },
      colorClass,
      isSelected ? 'is-selected' : '',
    ]"
    @click="emit('update:modelValue', !modelValue); test(e)"
    :disabled="disabled"
  >
    <span v-if="icon" class="icon">
      <i :class="`fas ${icon}`"></i>
    </span>
    <slot></slot>
  </button>
</template>

<script setup>
import { computed, defineEmits, ref, useAttrs } from "vue"

const props = defineProps({
  icon: String,
  text: String,
  color: String,
  disabled: Boolean,
  modelValue: {
    type: Boolean,
    default: undefined
  },
  isSelected: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:modelValue'])

const attrs = useAttrs()
const isLoading = ref(false)
const test = async () => {
  if(attrs.onClickAsync) {
    isLoading.value = true
    setTimeout(async () => {
      attrs.onClickAsync()
      isLoading.value = false
    }, "0")
    // console.log('click done')
  }
}

const isToggle = computed(() => props.modelValue !== undefined)
const toggleState = computed(() => props.modelValue === true)
const colorClass = computed(() => {
  if (props.color === undefined) return ''
  if (props.color.startsWith('is-')) return props.color
  const colors = ['white', 'light', 'dark', 'black', 'text', 'ghost', 'primary', 'link', 'info', 'success', 'wanring', 'danger']
  if (colors.includes(props.color)) return `is-${props.color}`
})
</script>