<template>
    <DynamicSubElement 
        v-for="output in outputs" :key="output"
        :input="output"></DynamicSubElement>
</template>

<script setup>
import { useStoreIsstt } from "@/stores/storeIsstt";
import { useStoreFiles } from "@/stores/storeFiles";
import { useStoreTeams } from "@/stores/storeTeams";
import { computed } from "vue";
import { XMLParser } from "fast-xml-parser";
import DynamicSubElement from "./DynamicSubElement.vue";

const issttStore = useStoreIsstt();
const fileStore = useStoreFiles();
const teamStore = useStoreTeams();

const props = defineProps({
    input: String
})

const outputs = computed(() => {
    return replaceVariables(props.input).filter(f => f)
})

const replaceVariables = (str) => {
  let temp = issttStore.replaceVariables(teamStore.replaceVariables(fileStore.replaceVariables(str)))
  const parser = new XMLParser({
    attributeNamePrefix : "",
    ignoreAttributes : false,
    preserveOrder: true,
    trimValues: false,
    unpairedTags: ["img"],
    htmlEntities: true
  });
  const a = parser.parse(`<div>${temp}</div>`)
  return a
}
</script>