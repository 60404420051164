import { ref, computed, watch } from "vue";
import { IssttYear } from "../../classes/IssttYear";
import { IssttEdition } from "../../classes/IssttEdition";
import { useRoute, useRouter } from 'vue-router';
import { useStoreTeams } from "../../stores/storeTeams";
import { useStoreGames } from "../../stores/storeGames";
import { useStoreScores } from "../../stores/storeScores";

export function useTournaments() {
  const editions = ref<IssttEdition[]>([]);
  const years = ref<IssttYear[]>([]);


  // Keep track of the current edition + year based on the url
  const route = useRoute();
  const currentEdition = computed(() => {
    const pathValue = route.params.isstt;
    return editions.value.find((edition) => edition.path === pathValue);
  })
  const currentYear = computed(() => {
    const edition = currentEdition.value
    if (!edition) return undefined
    const yearsOfEdition = years.value.filter((year) => year.edition === edition.id)
    return yearsOfEdition.find((years) => years.id === edition.currentActive)
  })

  const router = useRouter()
  const changeEdition = (id: string) => {
    router.push({params: { isstt: editions.value.find((e) => e.id === id)?.path }})
  }

  // Manage all the unsubscribe + subscribes when the current edition/year changes
  const teamStore = useStoreTeams();
  const gameStore = useStoreGames();
  const scoreStore = useStoreScores();
  watch(
    () => currentYear.value,
    (current: IssttYear | undefined, previous) => {
      if (current) { // check for not undefined
        teamStore.subscribe(current.teams);
        scoreStore.subscribe(current.scores);
        gameStore.subscribe(current.games);
      }
    }
  )

  return {
    editions,
    years,

    currentEdition,
    currentYear,

    changeEdition,
  }
}