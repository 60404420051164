<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">
        {{ team?.name }}
      </p>
    </header>
    <div class="card-content">
      <div class="content">
        <div class="tabs">
        <ul>
          <li :class="{'is-active' : currentSubPage === 'Schedule' || currentSubPage === undefined}"><a @click.prevent="router.push(`/${route.params.isstt}/teams/${currentPage}/Schedule`)">Schedule</a></li>
          <li :class="{'is-active' : currentSubPage === 'Score' }"><a @click.prevent="router.push(`/${route.params.isstt}/teams/${currentPage}/Score`)">Score</a></li>
          <li :class="{'is-active' : currentSubPage === 'Poules' }"><a @click.prevent="router.push(`/${route.params.isstt}/teams/${currentPage}/Poules`)">Poules</a></li>
          <li v-if="settingsVisible" :class="{'is-active' : currentSubPage === 'Settings' }" ><a @click.prevent="router.push(`/${route.params.isstt}/teams/${currentPage}/Settings`)">Settings</a></li>
        </ul>
      </div>
      <TeamSchedule v-if="currentSubPage === 'Schedule' || currentSubPage === undefined" :team="team"/>
      <TeamScore v-if="currentSubPage === 'Score'" :team="team"/>
      <TeamPoules v-if="currentSubPage === 'Poules'" :team="team"/>
      <TeamSettings v-if="settingsVisible && currentSubPage === 'Settings'"
        :team="team"
        @save="teamStore.saveTeams()"
      />
      </div>
    </div>
  </div>
</template>

<script setup>
import TeamSchedule from '@/components/teams/TeamSchedule.vue'
import TeamScore from '@/components/teams/TeamScore.vue'
import TeamPoules from '@/components/teams/TeamPoules.vue'
import TeamSettings from '@/components/teams/TeamSettings.vue'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStoreTeams } from '@/stores/storeTeams'
import { useStoreAuth } from '@/stores/storeAuth'

const router = useRouter()
const route = useRoute()
const currentPage = computed(() => route.params.team)
const currentSubPage = computed(() => route.params.subPage)

const teamStore = useStoreTeams()
const team = computed(() => teamStore.teams.find(t => t.id === route.params.team))
const isTeamAdmin = computed(() => {
  return team?.value?.managers?.includes(authStore.user?.id) === true
})

const authStore = useStoreAuth()
const settingsVisible = computed(() => {
  return ((authStore.isAdmin && authStore.adminView) || isTeamAdmin.value)
})

</script>
