<template>
    <div
        v-if="show"
        id="ball"
        class="ball"
        @click.prevent="show = false"
    ></div>
    <!-- <div class="output"></div> -->
</template>

<script setup>
import { onMounted, ref } from "@vue/runtime-core";

const show = ref(false)
const speed = ref(0.5)
const mobile_speed = ref(0.1)
const smooth = ref(10)
const ax = ref(0)
const ay = ref(0)
const ball = ref(null)
const x = ref(0)
const y = ref(0)
const vx = ref(0)
const vy = ref(0)
const r = ref(0)
const vr = ref(0)
const containerRef = '#body'
let time = new Date().getTime();

onMounted(() => {
    handleResize();
    ax.value = speed.value / (100 / smooth.value);
    ay.value = 0.001 * (speed.value / (100 / smooth.value));
    window.addEventListener('deviceorientation', handleOrientation);
    window.addEventListener('resize', handleResize);
    ballTimer();
})
const handleResize = () => {
    if (window.innerWidth > 1024) {
        show.value = false;
    } else {
        show.value = true;
    }
}
const handleOrientation = (event) => {
    // console.log('or', event)
    let x = event.beta;  // In degree in the range [-180,180]
    let y = event.gamma; // In degree in the range [-90,90]

    if (x > 90) {
    x = 90 - (x - 90);
    y = -y;
    }
    if (x < -90) {
    x = -90 + (x + 90);
    y = -y;
    }
    if (y > 90) {
    y = 90 - (y - 90);
    }
    if (y < -90) {
    y = -90 + (y + 90);
    }

    if (x != null) { ax.value = x / (90 / mobile_speed.value / smooth.value); }
    if (y != null) { ay.value = y / (90 / mobile_speed.value / smooth.value); }
    time = new Date().getTime();
}
const ballTimer = () => {
    if (show.value) {
    updateBall();
    }
    setTimeout(() => { ballTimer(); }, smooth.value);
}
const getMaxX = () => {
    const a = document.querySelector(containerRef).clientHeight;
    // const a2 = document.querySelector('#app').clientHeight;
    // const a = Math.max(a1, a2)
    const b = document.querySelector('#ball').clientHeight;
    return a - b;
}
const getMaxY = () => {
    const a = document.querySelector(containerRef).clientWidth;
    const b = document.querySelector('#ball').clientWidth;
    return a - b;
}
const updateBall = () => {
    if (ball.value === null) {
        ball.value = document.querySelector('#ball');
    } else {
    printOutput();
    const container = document.querySelector('#app-full-size');
    // console.log(container.scrollTop)
    const maxX = getMaxX() + container.scrollTop
    const maxY = getMaxY();

    // process acceleration
    vx.value += ax.value;
    vy.value += ay.value;

    // // there can be no speed toward a wall when against it.
    // if (x.value === maxX && vx.value > 0) { vx.value = 0; }
    // if (x.value === 0 && vx.value < 0) { vx.value = 0; }
    // if (y.value === maxY && vy.value > 0) { vy.value = 0; }
    // if (y.value === 0 && vy.value < 0) { vy.value = 0; }

    // the calculation of the position after a collision is incorrect
    // extra damping is introduced in this way to guarantee that
    // the position converges to 0
    if (x.value + vx.value > maxX) {
        // console.log('maxbump')
        // x.value = (maxX - ((maxX - x) / 2));
        x.value = maxX;
        if (vx.value < 0.01) vx.value = 0
        vx.value = -(vx.value / 2);
        // vr.value = vy.value * 10;
    } else if (x.value + vx.value < 0) {
        x.value = (x.value / 2);
        if (x.value < 0.01) { x.value = 0; }
        vx.value = -(vx.value / 2);
        // vr.value = -vy.value * 10;
    } else {
        x.value += vx.value;
    }

    if (y.value + vy.value > maxY) {
        // y.value = (maxY - ((maxY - y.value) / 2));
        y.value = maxY
        if (vy.value < 0.01) vy.value = 0
        vy.value = -(vy.value/ 2);
        // vr.value = -vx.value;
    } else if (y.value + vy.value < 0) {
        y.value = (y.value / 2);
        if (y.value < 0.01) { y.value = 0; }
        vy.value = -(vy.value / 2);
        // vr.value = vx.value;
    } else {
        y.value += vy.value;
    }

    // introduce rotation on contact with the wall
    // (in case the is no 'collision')
    if (x.value === maxX) { vr.value = vy.value *2; }
    if (x.value === 0) { vr.value = -vy.value *2; }
    if (y.value === maxY) { vr.value = -vx.value *2; }
    if (y.value === 0) { vr.value = vx.value *2; }

    r.value += vr.value;

    ball.value.style.top = `${x.value}px`;
    ball.value.style.left = `${y.value}px`;
    // ball.value.style.transform = `rotate(${r.value}deg)`;
    document.documentElement.style.setProperty(
        "--r",
        `${r.value}deg`
      );
    }
}
const printOutput = () => {
    const output = document.querySelector('.output');
    if (output) {
    output.innerHTML = `x: ${x.value}\n`;
    output.innerHTML += `y: ${y.value}\n`;
    output.innerHTML += `vx: ${vx.value}\n`;
    output.innerHTML += `vy: ${vy.value}\n`;
    output.innerHTML += `ax: ${ax.value}\n`;
    output.innerHTML += `ay: ${ay.value}\n`;
    output.innerHTML += `mx: ${getMaxX()}\n`;
    output.innerHTML += `my: ${getMaxY()}\n`;

        if (event != null) {
            const a = event.absolute;
            const z = event.alpha;
            const x = event.beta;  // In degree in the range [-180,180]
            const y = event.gamma; // In degree in the range [-90,90]
            output.innerHTML += `abs: ${a}\n`;
            output.innerHTML += `alpha: ${z}\n`;
            output.innerHTML += `beta : ${x}\n`;
            output.innerHTML += `gamma: ${y}\n`;
        }
    }
}

</script>

<style>
.ball {
    position: absolute;
    top   : 0px;
    left  : 0px;
    width : 40px;
    height: 40px;
    max-width : 40px;
    max-height: 40px;
    border-radius: 100%;
    overflow: hidden;
}
.ball::after {
    content: '';
    position: absolute;
    top   : 0px;
    left  : 0px;
    width : 40px;
    height: 40px;
    max-width : 40px;
    max-height: 40px;
    border-radius: 100%;
    background: url('@/assets/floorball.png') center / cover;
    z-index: 999;
    transform: rotate(var(--r));
    overflow: hidden;
}
</style>