import { v4 as uuidv4 } from 'uuid';

export class Placement {
  constructor(round, poule, position) {
    this.id = uuidv4();
    this.round = round;
    this.poule = poule;
    this.position = position;
    this.from = undefined;
    this.name = `${round}-${poule}-${position}`;
  }
}
