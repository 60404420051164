import { defineStore } from 'pinia'
import { db } from '@/firebase'
import { collection, updateDoc, doc, addDoc, onSnapshot } from 'firebase/firestore'
import { v4 as uuidv4 } from 'uuid';
import { TimeSlotForm } from "../classes/TimeSlotForm";
import { Placement } from "../classes/Placement";
import { Poule } from "../classes/Poule";
import { Round } from "../classes/Round";
import { Match } from '@/classes/Match'
import { TimeSlot } from '@/classes/TimeSlot'
import { useStoreScores } from '@/stores/storeScores'

const localeStringRegex = /([\d]+)\/([\d]+)\/([\d]+), ([\d]+):([\d]+):([\d]+)/
const dateTimeInputTemplate = "$3-$2-$1T$4:$5"
const gamesRef = collection(db, 'games')
let unsub = undefined

export const useStoreGames = defineStore('storeGames', {
  state: () => {
    return {
      currentGamesUid: undefined,
      rounds: [],
      placements: [],
      teamCountplacements: [],
      matches: [],
      timeSlotForms: [],
      timeSlots: [],
      teamCount: 0,
      loading: true,
      changed: false
    }
  },
  actions: {
    subscribe(ref) {
      this.unsubscribe();
      const unsub = onSnapshot(doc(gamesRef, ref), (doc) => {
        this.currentGamesUid = doc.id
        this.teamCount = doc.data().teamCount ?? 0
        this.placements = doc.data().placements ?? []

        const updatedRounds = doc.data().rounds ?? []
        this.rounds = updatedRounds.map(r => {
          return Object.assign(new Round(), r)
        }).sortBy('number')

        const updatedMatches = doc.data().matches ?? []
        this.matches = updatedMatches.map(m => {
          const temp = Object.assign(new Match(), m)
          if (temp.start) temp.start = new Date(temp.start)
          return temp
        }).sortBy('round', 'poule')

        this.timeSlotForms = doc.data().timeSlotForms ?? []

        const updatedTimeSlots = doc.data().timeSlots ?? []
        this.timeSlots = updatedTimeSlots.map(ts => {
          const temp = Object.assign(new TimeSlot(), ts)
          temp.start = new Date(temp.start)
          return temp
        }).sortBy('start','field')

        this.loading = false
      });
    },
    unsubscribe() {
      if (unsub !== undefined) unsub();
    },
    createGamesObject() {
      addDoc(gamesRef, JSON.parse(JSON.stringify({
        teamCount: this.teamCount,
        placements: this.placements,
        rounds: this.rounds,
        matches: this.matches,
        timeSlotForms: this.timeSlotForms,
        timeSlots: this.timeSlots
      })))
      .then(() => alert('Saved'))
      .catch((error) => alert(error.message))
    },
    savePoules() {
      if (this.currentGamesUid) {
        updateDoc(doc(gamesRef, this.currentGamesUid), JSON.parse(JSON.stringify({
          teamCount: this.teamCount,
          pouleSizes: this.pouleSizes,
          placements: this.placements,
          rounds: this.rounds,
        })))
        .then(() => alert('Saved'))
        .catch((error) => alert(error.message))
        } else {
        this.createGamesObject()
      }
    },
    saveMatches() {
      if (this.currentGamesUid) {
        updateDoc(doc(gamesRef, this.currentGamesUid), JSON.parse(JSON.stringify({
          matches: this.matches,
        })))
        .then(() => alert('Saved'))
        .catch((error) => alert(error.message))
        } else {
        this.createGamesObject()
      }
    },
    savePeriods() {
      if (this.currentGamesUid) {
        updateDoc(doc(gamesRef, this.currentGamesUid), JSON.parse(JSON.stringify({
          timeSlotForms: this.timeSlotForms,
        })))
        .then(() => alert('Saved'))
        .catch((error) => alert(error.message))
        } else {
        this.createGamesObject()
      }
    },
    saveSlots() {
      if (this.currentGamesUid) {
        updateDoc(doc(gamesRef, this.currentGamesUid), JSON.parse(JSON.stringify({
          timeSlots: this.timeSlots,
        })))
        .then(() => alert('Saved'))
        .catch((error) => alert(error.message))
        } else {
        this.createGamesObject()
      }
    },
    saveAssignments() {
      if (this.currentGamesUid) {
        updateDoc(doc(gamesRef, this.currentGamesUid), JSON.parse(JSON.stringify({
          matches: this.matches,
          timeSlots: this.timeSlots,
        })))
        .then(() => alert('Saved'))
        .catch((error) => alert(error.message))
        } else {
        this.createGamesObject()
      }
    },
    addRound() {
      const round = new Round(this.rounds.length + 1, 2)
      this.rounds.push(round)
      this.generatePlacements(round)
      this.changed = true
    },
    generateRounds() {
      const sizes = this.rounds.map(r => r.pouleSize)
      this.rounds = []
      for (let i = 0; i < this.sizes.length; i++) {
        const round = new Round(i+1, this.sizes[i])
        this.rounds.push(round)
      }
    },
    generatePlacements(round) {
      if (round) {
        this.placementCleanup(round.id)
        const pouleCount = Math.ceil(this.teamCount / round.pouleSize)
        for (let j = 0; j < pouleCount; j++) {
          for (let k = 0; k < round.pouleSize; k++) {
            const placement = new Placement(round.number, j + 1, k + 1)
            this.placements.push(placement)
          }
        }
      } else {
        this.placements = []
        this.rounds.forEach((round) => {
          const pouleCount = Math.ceil(this.teamCount / round.pouleSize)
          for (let j = 0; j < pouleCount; j++) {
            for (let k = 0; k < round.pouleSize; k++) {
              const placement = new Placement(round.number, j + 1, k + 1)
              this.placements.push(placement)
            }
          }
        })
      }
    },
    placementCleanup(roundId) {
      const round = this.rounds.find(r => r.id === roundId)
      const nextRound = this.rounds.find(r => r.number === round.number + 1)
      const toRemove = this.placements.filter(p => p.round === round.number).map(p => p.id)
      this.placements = this.placements.filter(p => !toRemove.includes(p.id))
      if (nextRound) {
        this.placements.filter(p => p.round === nextRound.number).forEach(p => p.from = undefined)
      }
    },
    increaseGroup(roundId) {
      const temp = this.rounds.find(r => r.id === roundId)
      if (temp && temp.groupSize < this.teamCount) temp.groupSize++
    },
    decreaseGroup(roundId) {
      const temp = this.rounds.find(r => r.id === roundId)
      if (temp && temp.groupSize > 2) temp.groupSize--
    },
    deletePoule(roundId) {
      const round = this.rounds.find(r => r.id === roundId)
      const roundIndex = this.rounds.findIndex(r => r.id === roundId)
      if (round) {
        this.placementCleanup(round.id)
        this.rounds.splice(roundIndex, 1)
      }
    },
    decreasePouleSize(roundId) {
      const round = this.rounds.find(r => r.id === roundId)
      if (round.pouleSize > 2) {
        round.pouleSize -= 1
        this.generatePlacements(round)
      }
    },
    increasePouleSize(roundId){
      const round = this.rounds.find(r => r.id === roundId)
      if (round.pouleSize < this.teamCount / 2) {
        round.pouleSize += 1
        this.generatePlacements(round)
      }
    },
    placement(id) {
      return this.placements.find(p => p.id === id)
    },
    generateMatches(roundId) {
      if (roundId) {
        const temp = this.rounds.find(r => r.id === roundId)
        this.matches = this.matches.filter(m => m.round !== temp.number)
        const round = this.placementsGrouped[temp.number]
        Object.entries(round.poules).forEach(([p, poule]) => {
          poule.forEach((homePlacement) => {
            poule.forEach((awayPlacement) => {
              if (homePlacement !== awayPlacement) {
                const duplicate = this.matches.find(match => match.home === homePlacement.id && match.away === awayPlacement.id)
                const reverseDuplicate = this.matches.find(match => match.away === homePlacement.id && match.home === awayPlacement.id)
                if (!duplicate && !reverseDuplicate) {
                  this.matches.push(new Match(homePlacement, awayPlacement))
                }
              }
            })
          })
        })
      } else {
        this.matches = []
        Object.entries(this.placementsGrouped).forEach(([r, round]) => {
          Object.entries(round.poules).forEach(([p, poule]) => {
            poule.forEach((homePlacement) => {
              poule.forEach((awayPlacement) => {
                if (homePlacement !== awayPlacement) {
                  const duplicate = this.matches.find(match => match.home === homePlacement.id && match.away === awayPlacement.id)
                  const reverseDuplicate = this.matches.find(match => match.away === homePlacement.id && match.home === awayPlacement.id)
                  if (!duplicate && !reverseDuplicate) {
                    this.matches.push(new Match(homePlacement, awayPlacement))
                  }
                }
              })
            })
          })
        })
      }
    },
    addTimeSlotForm() {
      let start = new Date(this.timeSlotForms.last()?.endDateTimeInput)
      if (!start) start = new Date()
      const form = new TimeSlotForm(start, start)
      this.timeSlotForms.push(form)
    },
    updateRanking(round, value) {
      const temp = this.rounds.find(r => r.number === round.number)
      temp.ranking = value
    },
    updatePlacement(round, value) {
      const temp = this.rounds.find(r => r.number === round.number)
      temp.placement = value
    },
    assignSlot(matchId, timeSlotId) {
      const match = this.matches.find(m => m.id === matchId)
      const timeSlot = this.timeSlots.find(m => m.id === timeSlotId)
      if (match && timeSlot) match.assingSlot(timeSlot)
    },
    assignRef(matchId, placementId) {
      const match = this.matches.find(m => m.id === matchId)
      if (match) match.ref = placementId
    },
    // timeSlots
    timeSlotClear() {
      this.timeSlots = []
    },
    timeSlotAdd(timeslot) {
      this.timeSlots.push(timeslot)
      this.timeSlots.sortBy('start', 'field')
    }
  },
  getters: {
    placementsGrouped(state) {
      const byRound = state.placements.groupBy('round')
      Object.keys(byRound).forEach(key => {
        key = Number(key)
        const r = state.rounds.find(r => r.number === key)
        byRound[key] = Object.assign({}, r, {poules: byRound[key].groupBy('poule')})
      })
      return byRound
    },
    matchesGrouped(state) {
      const byRound = state.matches.groupBy('round')
      Object.keys(byRound).forEach(key => {
        key = Number(key)
        const r = state.rounds.find(r => r.number === key)
        byRound[key] = Object.assign({}, r, {poules: byRound[key].groupBy('poule')})
      })
      return byRound
    },
    timeSlotsGrouped(state) {
      return state.timeSlots.groupBy('field')
    },
    placementsScored(state) {
      const scoreStore = useStoreScores()

      const all = state.placements.map((p) => {
        const placementId = p.id
        const ms = state.matches.filter((m) => {
          return m.home === placementId || m.away === placementId
        })
        let selfTotal = 0
        let otherTotal = 0
        const a = {
          id: placementId,
          matches: ms.map((m) => {
            const temp = Object.assign({}, m)
            const score = scoreStore.scores.find((s) => s.match === m.id)
            if (score === undefined) {
              // console.warn('no score for', m)
              return undefined
            }
            if (m.home === placementId) {
              temp.self = score.home
              temp.other = score.away
            } else {
              temp.self = score.away
              temp.other = score.home
            }
            temp.played = (temp.self !== undefined)
            if (temp.played) {
              selfTotal += temp.self
              otherTotal += temp.other
            }
            return temp
          }).filter((m) => m !== undefined)
        }
        a.selfTotal = selfTotal
        a.otherTotal = otherTotal
        a.goalTotal = selfTotal - otherTotal
        a.wins = a.matches.filter((m) => m.played && m.self > m.other).length
        a.draws = a.matches.filter((m) => m.played && m.self === m.other).length
        a.loses = a.matches.filter((m) => m.played && m.self < m.other).length
        a.points = a.matches.filter((m) => m.played && m.self > m.other).length * 3 + a.matches.filter((m) => m.played && m.self === m.other).length
        a.to = state.placements.filter((next) => {
          if (next.from === undefined) return false
          const data = next.from.split('-')
          return p.round === parseInt(data[0]) && p.poule === parseInt(data[1])
        })
        return a
      })
      const result = {}
      all.forEach((m) => result[m.id] = m)
      return result
    }
  }
})