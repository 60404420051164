<template>
  <NavigationTopBar @loaded="emit('loaded')"><slot></slot></NavigationTopBar>
  <div class="columns mx-0 mt-1" style="flex: auto; margin-top: 0px !important">
    <NavigationDesktopMenu><slot></slot></NavigationDesktopMenu>
    <div class="column">
      <RouterView />
    </div>
  </div>
</template>

<script setup>
import NavigationDesktopMenu from './NavigationDesktopMenu.vue';
import NavigationTopBar from './NavigationTopBar.vue';

const emit = defineEmits(['loaded'])
</script>