<template>
  <div class="toasts-container">
    <div v-for="(toast, index) in toastStore.toasts"
      class="toast-container"
      :class="{
        'first': index===0,
        'second': index===1,
        'third': index===2,
        'fourth': index===3,
        'fifth': index===4
      }"
      >
      <div
      class="notification"
      :class="{
        'is-success': toast.type === 'success',
        'is-warning': toast.type === 'warning',
        'is-danger': toast.type === 'error'
      }"
    >
      <button v-if="toast.closable" class="delete" @click="toastStore.cycle()"></button>
      {{ toast.message }}
    </div>
    </div>
  </div>
</template>

<script setup>
import { useStoreToast } from '@/stores/toast/storeToast'
const toastStore = useStoreToast()

</script>

<style scoped>
.toasts-container {
  background-color: transparent;
  pointer-events: none;
  position: absolute;
  bottom: 0;
  height: 6rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  overflow: hidden;
  z-index: 1;
}
.toast-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  &:not(:first-child) {
    margin-top: -2rem;
  }
  &.second {
    z-index: -1;
    transform: scaleX(0.95) scaleY(0.95);
  }
  &.third {
    z-index: -2;
    transform: scaleX(0.90) scaleY(0.90);
  }
  &.fourth {
    z-index: -3;
    transform: scaleX(0.85) scaleY(0.85);
  }
  &.fifth {
    z-index: -4;
    transform: scaleX(0.80) scaleY(0.80);
  }
}
</style>