// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, doc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAue6YKP5WtALetNu6rhvDZwmeVfnxlIM0",
  authDomain: "isstt-next.firebaseapp.com",
  projectId: "isstt-next",
  storageBucket: "isstt-next.appspot.com",
  messagingSenderId: "194417298227",
  appId: "1:194417298227:web:6ba083865e4415b3784c46"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export {
    db, doc,
    auth,
    storage
}