<template>
    <div class="buttons has-addons ml-auto" v-if="authStore.isAdmin && authStore.adminView">
        <button
            v-if="!onlySave"
            class="button is-small ml-auto"
            @click="$emit('add')"
        >
            Add {{ name }}
        </button>
        <button
            class="button is-success is-small"
            @click="$emit('save')"
        >
            Save {{ name }}s
        </button>
    </div>
</template>

<script setup>
import { useStoreAuth } from '@/stores/storeAuth'
const authStore = useStoreAuth()

const props = defineProps({
    name: String,
    onlySave: Boolean
})
</script>