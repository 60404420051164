import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AuthView from '../views/AuthView.vue'
import InfoView from '@/views/InfoView.vue'
import TeamView from '@/views/TeamView.vue'
import AccountView from '@/views/AccountView.vue'
import { useStoreAuth } from '../stores/storeAuth'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '',
      redirect: to => {
        return { path: '/student' }
      },
    },
    {
      path: '/:isstt',
      name: 'home',
      component: HomeView,
    },
    {
      path: '/:isstt/info/:page/:subPage',
      name: 'info',
      component: InfoView
    },
    {
      path: '/:isstt/teams/:team',
      name: 'team',
      component: TeamView
    },
    {
      path: '/:isstt/teams/:team/:subPage',
      name: 'teamSub',
      component: TeamView
    },
    {
      path: '/:isstt/auth',
      name: 'auth',
      component: AuthView
    },
    {
      path: '/:isstt/account',
      name: 'account',
      component: AccountView
    },
    {
      path: '/:isstt/admin',
      name: 'admin',
      children: [{
        path: '',
        alias: 'Pages',
        name: 'Pages',
        component: () => import(/* webpackChunkName: "AdminView" */ '../views/admin/Pages.vue')
      },
      {
        path: 'Variables',
        name: 'Variables',
        component: () => import(/* webpackChunkName: "AdminView" */ '../views/admin/Variables.vue')
      },
      {
        path: 'Settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "AdminView" */ '../views/admin/Settings.vue')
      },
      {
        path: 'Teams',
        name: 'Teams',
        component: () => import(/* webpackChunkName: "AdminView" */ '../views/admin/Teams.vue')
      },
      {
        path: 'Teams/Administration',
        name: 'TeamsAdministration',
        component: () => import(/* webpackChunkName: "AdminView" */ '../views/admin/TeamAdministration.vue')
      },
      {
        path: 'Schedule/Poules',
        alias: 'Schedule',
        name: 'SchedulePoules',
        component: () => import(/* webpackChunkName: "AdminView" */ '../views/admin/Schedule/Poules.vue')
      },
      {
        path: 'Schedule/Matches',
        name: 'ScheduleMatches',
        component: () => import(/* webpackChunkName: "AdminView" */ '../views/admin/Schedule/Matches.vue')
      },
      {
        path: 'Schedule/TimeSlots',
        name: 'ScheduleTimeSlots',
        component: () => import(/* webpackChunkName: "AdminView" */ '../views/admin/Schedule/TimeSlots.vue')
      },
      {
        path: 'Schedule/ScheduleMatches',
        name: 'ScheduleScheduleMatches',
        component: () => import(/* webpackChunkName: "AdminView" */ '../views/admin/Schedule/ScheduleMatches.vue')
      },
      {
        path: 'Management/Scores',
        alias: 'Management',
        name: 'ManagementScores',
        component: () => import(/* webpackChunkName: "AdminView" */ '../views/admin/Management/Scores.vue')
      },
      {
        path: 'Management/Poules',
        name: 'ManagementPoules',
        component: () => import(/* webpackChunkName: "AdminView" */ '../views/admin/Management/Poules.vue')
      },
      {
        path: 'Management/Print',
        name: 'ManagementPrint',
        component: () => import(/* webpackChunkName: "AdminView" */ '../views/admin/Management/Print.vue')
      }]
    }
  ]
})

const accountRoutes = ['account']
const adminRoutes = [
  'admin',
  'Pages',
  'Variables',
  'Settings',
  'Teams',
  'TeamsAdministration',
  'SchedulePoulses',
  'ScheduleMatches',
  'ScheduleTimeSlots',
  'ScheduleScheduleMatches',
  'ManagementScores',
  'ManagementPoules',
  'ManagementPrint'
]

router.beforeEach(async (to, from) => {
  const storeAuth = useStoreAuth()
  if (
    (accountRoutes.includes(to.name) ||
     adminRoutes.includes(to.name)
    ) && !storeAuth.user) {
    return { name: 'auth', query: { from: window.location.pathname }, params: to.params }
  }
  if (adminRoutes.includes(to.name) && storeAuth.user && !storeAuth.isAdmin) {
    return { name: 'home', params: to.params }
  }
})

export default router
