import { defineStore } from 'pinia'
import { db } from '@/firebase'
import { collection, updateDoc, doc, addDoc, onSnapshot } from 'firebase/firestore'
import { v4 as uuidv4 } from 'uuid';
import { Score } from '@/classes/Score';

const scoresRef = collection(db, 'scores')
let unsub = undefined

export const useStoreScores = defineStore('storeScores', {
  state: () => {
    return {
      currentScoresUid: undefined,
      scores: [],
      scoresOriginal: [],
    }
  },
  actions: {
    subscribe(ref) {
      this.unsubscribe();
      const unsub = onSnapshot(doc(scoresRef, ref), (doc) => {
        this.currentScoresUid = doc.id
        const updatedScores = doc.data().scores ?? []
        this.scores = updatedScores.map(s => {
          const temp = Object.assign(new Score(), s)
          if (!isNaN(temp.home)) temp.home = parseInt(temp.home)
          if (!isNaN(temp.away)) temp.away = parseInt(temp.away)
          return temp
        })
        this.scoresOriginal = updatedScores.map(s => {
          const temp = Object.assign(new Score(), s)
          if (!isNaN(temp.home)) temp.home = parseInt(temp.home)
          if (!isNaN(temp.away)) temp.away = parseInt(temp.away)
          return temp
        })
      });
    },
    unsubscribe() {
      if (unsub !== undefined) unsub();
    },
    createScoresObject() {
      addDoc(scoresRef, JSON.parse(JSON.stringify({
        scores: this.scores
      })))
    },
    saveScores() {
      if (this.currentScoresUid) {
        updateDoc(doc(scoresRef, this.currentScoresUid), JSON.parse(JSON.stringify({
          scores: this.scores
        })))
        .then(() => alert('Saved'))
        .catch((error) => alert(error.message))
      } else {
        this.createScoresObject()
      }
    },
  }
})
