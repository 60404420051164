<template>
  <div>
    <Card title="Account">
      <Button @click="resetPassword()">
        Reset password
      </Button>
    </Card>
    <Card title="Teams">
      <template v-slot>
        <table v-if="managedTeams?.length > 0" class="table">
          <thead>
            <th>
              Team
            </th>
          </thead>
          <tbody>
            <tr v-for="team in managedTeams">
              <td>
                <NavigationLink :url="`/teams/${team.id}/Settings`" :text="team.name || 'Unnamed team'"/>
              </td>
              <td>
                <Button icon="fa-trash" @click="deleteTeamModal(team.id)" :disabled="team.participationConfirmed"></Button>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
      <template v-slot:footer>
        <a href="#" class="card-footer-item" @click="createNewTeam()">Register new team</a>
      </template>
    </Card>
    <ModalCard
      :open="showDeleteTeamModal"
      :title="'Are you sure?'"
      @close="showDeleteTeamModal = false"
    >
      You are about to delete team "{{ teamToDelete?.name || 'Unnamed team' }}". This cannot be recovered. All information about this team will be lost. Including, administrative data such as payments, match results, etc.
      <template #footer>
        <Button @click="deleteTeam()" color="danger">Delete</Button>
      </template>
    </ModalCard>
  </div>
</template>

<script setup>
import Button from '@/components/generic/Button.vue'
import Card from '@/components/generic/Card.vue'
import Icon from '@/components/generic/Icon.vue'
import ModalCard from '@/components/generic/ModalCard.vue'
import NavigationLink from '@/components/navigation/NavigationLink.vue'

import { useStoreAuth } from '@/stores/storeAuth'
import { useStoreTeams } from '@/stores/storeTeams'
import { computed, ref } from 'vue';
const authStore = useStoreAuth()
const teamStore = useStoreTeams()

const resetPassword = () => {
  authStore.resetPassword(authStore.user.email)
}

const managedTeams = computed(() => {
  const uids = teamStore.teams.filter(it => it.managers.includes(authStore.user.id))
  return uids
})

function createNewTeam() {
  const newTeams = teamStore.addTeam(1);
  const team = newTeams[0];
  team.managers.push(authStore.user.id);
  team.contactPersonEmail = authStore.user.email;
  teamStore.saveTeams();
}

const showDeleteTeamModal = ref(false)
const deleteTeamUuid = ref('')
const teamToDelete = computed(() => {
  teamStore.teams.find(t => t.id === deleteTeamUuid.value)
})
function deleteTeamModal(teamUuid) {
  deleteTeamUuid.value = teamUuid
  showDeleteTeamModal.value = true
}
function deleteTeam() {
  teamStore.deleteTeam(deleteTeamUuid.value)
  showDeleteTeamModal.value = false
}

</script>