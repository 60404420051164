import { v4 as uuidv4 } from 'uuid';

export class Team {
  constructor () {
    // generic
    this.id = uuidv4(),
    // form
    this.name = "",
    this.association = "",
    this.contactPersonName = "",
    this.contactPersonEmail = undefined,
    this.contactPersonPhone = "",
    this.sendInvites = false,
    this.sharePhoneWithOtherTeams = false,
    this.playerCount = 4, // 4-10
    this.cabinSleepCount = 0, // 0-10
    this.cabinAssignedCount = 0, // 0-10
    this.sportCenterSleepCount = 0, // 0-10
    this.foodVegaCount = 0,
    this.foodVeganCount = 0,
    this.foodGlutenFreeCount = 0,
    this.foodLactoseFreeCount = 0,
    this.foodNutAndPindaFreeCount = 0,
    this.foodComments = "",
    this.otherComments = "",
    // privacy
    this.privacyAdministrativeUpdates = false,
    this.privacySharePhoneForCabin = false,
    this.privacyInviteNextYear = false,
    // administrative
    this.participationConfirmed = false,
    this.payment1 = undefined,
    this.payment2 = undefined,
    this.payment3 = undefined,
    this.payment4 = undefined,
    // technical
    this.tags = []
    this.managers = []
  }

  totalEntranceCosts(costsPerPerson) {
    return (parseFloat(this.playerCount) || 0) * (parseFloat(costsPerPerson) || 0)
  }

  totalCabinCosts(costsCabin) {
    return (parseFloat(costsCabin) || 0) * ((parseFloat(this.cabinAssignedCount) || 0) * 2)
  }

  cabinDepositCosts(cabinDeposit) {
    return (this.cabinAssignedCount > 0) ? (parseFloat(cabinDeposit) || 0) : 0
  }

  totalCosts(costsPerPerson, costsCabin) {
    if (this.cabinAssignedCount > 0) {
      return this.totalEntranceCosts(costsPerPerson) + this.totalCabinCosts(costsCabin)
    } else {
      return this.totalEntranceCosts(costsPerPerson)
    }
  }

  residualCosts(costsPerPerson, costsCabin, cabinDeposit) {
    if (this.cabinAssignedCount > 0) {
      return this.totalCosts(costsPerPerson, costsCabin) - (parseFloat(this.payment1) || 0) + (parseFloat(cabinDeposit) || 0)
    } else {
      return this.totalCosts(costsPerPerson, costsCabin) - (parseFloat(this.payment1) || 0)
    }
  }

  expectedReturn(costsPerPerson, costsCabin) {
    const result = this.totalCosts(costsPerPerson, costsCabin) - (parseFloat(this.payment1) || 0) - (parseFloat(this.payment2) || 0) - (parseFloat(this.payment3) || 0)
    if (result < 0) return Math.abs(result)
    return result * -1
  }

}