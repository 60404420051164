<template>
  <AdminButtons
    name="Note"
    @add="issttStore.addNote()"
    @save="issttStore.saveNotes()"
  />
  <div class="title">News</div>
  <InfoCard
    v-for="(note, index) in issttStore.notes.sort((a, b) => (a.date > b.date) ? -1 : 1)"
    :key="note.id"
    v-model:title="note.title"
    v-model:content="note.content"
    v-model:visible="note.visible"
    :date="note.date"
    :id="note.id"
    :hideMove="true"
    @delete="issttStore.notes.splice(index, 1)"
    @copy="issttStore.copyNote(note)"
  />
</template>

<script setup>
import AdminButtons from '../components/AdminButtons.vue';
import InfoCard from '@/components/InfoCard.vue'
import { useStoreIsstt } from '@/stores/storeIsstt'
const issttStore = useStoreIsstt()
</script>
