<template>
  <div>
    <div class="login">
      <input
        placeholder="email"
        type="text"
        class="input is-small mb-1"
        v-model="userName"
      >
      <input
        placeholder="password"
        type="password"
        class="input is-small"
        v-model="password"
        @keypress.enter="login"
      >
      <div class="buttons has-addons is-centered mt-2">
        <button
          class="button is-small is-success"
          @click.prevent="login"
          :disabled="userName.length < 1 || password.length < 1"
        >
          Login
        </button>
        <button
          class="button is-small"
          @click.prevent="register"
        >
          Register
        </button>
        <button
          class="button is-small"
          @click.prevent="resetPassword"
          :disabled="userName.length < 1 || password.length > 0"
        >
          Reset password
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStoreAuth } from '@/stores/storeAuth'
const storeAuth = useStoreAuth()

const userName = ref('')
const password = ref('')
const login = () => {
  storeAuth.loginUser(userName.value, password.value)
}
const register = () => {
  storeAuth.registerUser(userName.value, password.value)
}
const resetPassword = () => {
  storeAuth.resetPassword(userName.value)
}

</script>
