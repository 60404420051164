import { v4 as uuidv4 } from 'uuid';

export class Match {
  constructor(homePlacement, awayPlacement) {
    this.id = uuidv4();
    this.home = homePlacement?.id;
    this.away = awayPlacement?.id;
    this.ref = undefined;
    this.start = undefined;
    this.round = homePlacement?.round;
    this.poule = homePlacement?.poule;
    this.timeSlot = undefined;
    this.homePosition = homePlacement?.position
    this.awayPosition = awayPlacement?.position
  }

  assingSlot(timeSlot) {
    this.timeSlot = timeSlot.id;
    this.start = timeSlot.start;
    this.field = timeSlot.field;
    timeSlot.match = this;
  }
}
