<template>
  <div v-if="isMobile">
    <table  class="table is-bordered is-striped is-narrow small">
      <tbody>
        <template
          v-for="match in teamMatches"
          :key="match.id"
        >
          <tr>
            <td><p class="help">Start</p>{{ match.timeSlot.start?.pretty() }}</td>
            <td><p class="help">Round</p>{{ match.round }}</td>
            <td><p class="help">Field</p>{{ match.field }}</td>
          </tr>
          <tr>
            <td class="last"><p class="help">Home</p><RouterLink :to="`/teams/${teamStore.byPlacement(match.home)?.id}`">{{ teamStore.byPlacement(match.home)?.name }}</RouterLink></td>
            <td class="last"><p class="help">Away</p><RouterLink :to="`/teams/${teamStore.byPlacement(match.away)?.id}`">{{ teamStore.byPlacement(match.away)?.name }}</RouterLink></td>
            <td v-if="match.score?.home === undefined" class="last"><p class="help">Score</p>unplayed</td>
            <td v-else class="last"><p class="help">Score</p>{{ match.score.home }} - {{ match.score.away }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
  <div v-else>
    <table class="table is-bordered is-striped">
      <thead>
        <tr>
          <th>Start</th>
          <th>Round</th>
          <th>Field</th>
          <th>Home</th>
          <th>Away</th>
          <th>Score</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="match in teamMatches"
          :key="match.id"
        >
          <td>{{ match.timeSlot.start?.pretty() }}</td>
          <td>{{ match.round }}</td>
          <td>{{ match.field }}</td>
          <td><RouterLink :to="`/teams/${teamStore.byPlacement(match.home)?.id}`">{{ teamStore.byPlacement(match.home)?.name }}</RouterLink></td>
          <td><RouterLink :to="`/teams/${teamStore.byPlacement(match.away)?.id}`">{{ teamStore.byPlacement(match.away)?.name }}</RouterLink></td>
          <td v-if="match.score?.home === undefined">unplayed</td>
          <td v-else>{{ match.score.home }} - {{ match.score.away }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { useStoreGames } from '@/stores/storeGames'
import { useStoreTeams } from '@/stores/storeTeams'
import { useStoreScores } from '@/stores/storeScores'
import { computed } from '@vue/runtime-core'

const gameStore = useStoreGames()
const teamStore = useStoreTeams()
const scoreStore = useStoreScores()

const props = defineProps({
  team: Object
})

const teamMatches = computed(() => {
  return gameStore.matches.filter(m => {
    return props.team.tags.includes(m.home) || props.team.tags.includes(m.away)
  }).map(m => {
    const ts = {timeSlot: gameStore.timeSlots.find(ts => ts.id === m.timeSlot)}
    const score = {score: scoreStore.scores.find(s => s.match === m.id) }
    return Object.assign({}, m, ts, score)
  }).sortBy('timeSlot.start')
})

const isMobile = computed (() => {
   if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
     return true
   } else {
     return false
   }
 })
</script>

<style scoped>
.help {
  font-size: 0.5rem;
}
.small {
  font-size: 0.75rem;
}
.last {
  border-bottom: 1px solid black;
}
</style>