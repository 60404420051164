<template>
  <aside class="menu is-hidden-touch">
    <slot></slot>
  </aside>
</template>

<script setup>
</script>

<style scoped>
.menu{
  background-color: rgba(255,255,255,.97);
  padding: 2rem;
  margin: 0;
}
.menu-label_item {
  white-space: nowrap;
}
</style>