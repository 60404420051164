import { computed, ref } from "vue";
import { defineStore } from 'pinia'
import { db, storage } from '../firebase'
import { collection, updateDoc, doc, addDoc, onSnapshot } from 'firebase/firestore'
import { ref as storageRef, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';
import { Page } from '../classes/Page'
import { Variable } from "../classes/Variable";
import { Note } from "../classes/Note";
import { Setting } from "../classes/Setting";
import { useTournaments } from "./isstt/tournaments";
import { useStoreToast } from "./toast/storeToast";

export const useStoreIsstt = defineStore('storeIsstt', () => {
  const toastStore = useStoreToast()
  const { 
    editions,
    years,
    currentEdition,
    currentYear,
    changeEdition,
  } = useTournaments();

  const ISSTT_REF = collection(db, 'isstt')

  const currentIssttUid = ref<string>();
  const pages = ref<Page[]>([]);
  const variables = ref<Variable[]>([]);
  const notes = ref<Note[]>([]);
  const settings = ref<Setting[]>([]);
  const loading = ref(true);
  const adminPrepareLock = ref(false);

  function subscribe() {
    const unsub = onSnapshot(doc(ISSTT_REF, "Zt9qTV3flfoH2VD97THu"), (doc) => {
      const data = doc.data()!!
      currentIssttUid.value = doc.id
      pages.value = data.pages ?? []
      variables.value = data.variables ?? []
      notes.value = data.notes ?? []
      settings.value = data.settings ?? []
      adminPrepareLock.value = data.adminPrepareLock
      loading.value = false
      years.value = data.yearsAll // different name was used for smooth migration / not breaking db during development
      editions.value = data.editions
    });
  }

  function createIssttObject() {
    addDoc(ISSTT_REF, {
      pages: pages.value,
      variables: variables.value,
      notes: notes.value,
      settings: settings.value,
    })
  }

  function savePages() {
    if (currentIssttUid.value) {
      updateDoc(doc(ISSTT_REF, currentIssttUid.value), {
        pages: pages.value
      })
      .then(() => toastStore.success('Saved'))
      .catch((error) => toastStore.error(error.message))
    } else {
      createIssttObject()
    }
  }

  function addPage() {
    if (!pages.value) pages.value = []
    pages.value.push(new Page());
  }

  function addInfoCard(page, subPage) {
    if (!page || !subPage) return
    let pageToAddTo = pages.value.find(p => p.title === page)
    if (!pageToAddTo) return
    const subPageToAddto = pageToAddTo.contentAsPages().find(sp => sp.title === subPage)
    if (!subPageToAddto) return
    if (subPageToAddto.content === undefined) subPageToAddto.content = []
    subPageToAddto.addInfoCard()
  }

  function saveVariables() {
    if (currentIssttUid.value) {
      updateDoc(doc(ISSTT_REF, currentIssttUid.value), {
        variables: variables.value
      })
      .then(() => toastStore.success('Saved'))
      .catch((error) => toastStore.error(error.message))
    } else {
      createIssttObject()
    }
  }

  function addVariable() {
    if (!variables.value) variables.value = []
    variables.value.push(new Variable());
  }

  function replaceVariables(str: string) {
    let result = `${str}`
    // replace newlines
    result = result.replaceAll('\n','<br/>')
    // replace bold
    const boldRegex = /\*\*(.*?)\*\*/g
    result = result.replaceAll(boldRegex,'<strong>$1</strong>')
    // replace links
    const linkRegex = /\[(.+?)\]\((.+?)\)/g
    result = result.replaceAll(linkRegex,'<a href="$2">$1</a>')
    // replace variables
    variables.value.forEach(variable => {
      result = result.replaceAll('${'+variable.name+'}', variable.value)
    });
    return result
  }

  function saveNotes() {
    if (currentIssttUid.value) {
      updateDoc(doc(ISSTT_REF, currentIssttUid.value), {
        notes: notes.value
      })
      .then(() => toastStore.success('Saved'))
      .catch((error) => toastStore.error(error.message))
    } else {
      createIssttObject()
    }
  }

  function addNote() {
    if (!notes.value) notes.value = []
    notes.value.push(new Note());
  }

  function copyNote(noteToCopy: Note) {
    if (notes.value === undefined) notes.value = []
    notes.value.push({
      id: uuidv4(),
      title: noteToCopy.title || "",
      content: noteToCopy.content || "",
      visible: noteToCopy.visible || true,
      date: Date.now()
    });
  }

  function saveSettings() {
    if (currentIssttUid.value) {
      updateDoc(doc(ISSTT_REF, currentIssttUid.value), {
        settings: settings.value,
        editions: editions.value,
      })
      .then(() => toastStore.success('Saved'))
      .catch((error) => toastStore.error(error.message))
    } else {
      createIssttObject()
    }
  }

  function addSetting(settingName) {
    if (!settings.value) settings.value = []
    this.settings.push(new Setting(settingName));
  }

  const backgroundImageName = computed(() => {
    return settings.value.find(setting => setting.name === 'backgroundImage')?.value
  })

  const backgroundImageRef = computed(() => {
    const name = backgroundImageName.value
    if (!name) return
    return storageRef(storage, name)
  })

  const logoImageName = computed(() => {
    return settings.value.find(setting => setting.name === 'logoImage')?.value
  })

  const logoImageRef = computed(() => {
    const name = logoImageName.value
    if (!name) return
    return storageRef(storage, name)
  })

  return {
    editions,
    years,
    currentEdition,
    currentYear,
    changeEdition,

    currentIssttUid,
    pages,
    variables,
    notes,
    settings,
    loading,
    adminPrepareLock,

    subscribe,
    createIssttObject,
    savePages,
    addPage,
    addInfoCard,
    saveVariables,
    addVariable,
    replaceVariables,
    addNote,
    copyNote,
    saveSettings,
    addSetting,

    backgroundImageRef,
    logoImageRef,
  }
})
