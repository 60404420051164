import { v4 as uuidv4 } from 'uuid';

export class TimeSlot {
  constructor(start, duration, field, form, playing) {
    this.id = uuidv4(),
    this.form = form;
    this.start = start,
    this.duration = duration,
    this.field = field,
    this.match = undefined,
    this.playing = playing;
  }
}
