<template>
  <InfoCard
    v-for="(card, index) in currentPageObject?.content"
    :key="card.id"
    v-model:title="card.title"
    v-model:content="card.content"
    :id="card.id"
    :visible="true"
    :hideVisibleToggle="true"
    :hideCopy="true"
    @moveUp="currentPageObject.content.move(index, index - 1)"
    @moveDown="currentPageObject.content.move(index, index + 1)"
    @delete="currentPageObject.content.splice(index, 1)"
  />
  <AdminButtons
    name="Page"
    @add="issttStore.addInfoCard(currentPage, currentSubPage)"
    @save="issttStore.savePages()"
  />
</template>

<script setup>
import { computed, reactive } from 'vue'
import { useRoute } from 'vue-router'
import AdminButtons from '../components/AdminButtons.vue'
import InfoCard from '@/components/InfoCard.vue'
import { useStoreIsstt } from '@/stores/storeIsstt'
import { useStoreAuth } from '@/stores/storeAuth'

// rendering
const issttStore = useStoreIsstt()
const route = useRoute()
const currentPage = computed(() => route.params.page)
const currentSubPage = computed(() => route.params.subPage)
const currentPageObject = computed(() => {
  let resultPage = issttStore.pages.find(page => page.title === currentPage.value)
  let resultSubPage
  if (resultPage && Array.isArray(resultPage.content) && currentSubPage.value) {
    resultSubPage = resultPage.content.find(subPage => subPage.title === currentSubPage.value)
  }
  return resultSubPage ?? resultPage
})

// admin edit
const authStore = useStoreAuth()
</script>
