import { defineStore } from 'pinia'
import { storage } from '@/firebase'
import { ref, listAll, getDownloadURL, uploadBytes, deleteObject, getMetadata } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';

export const useStoreFiles = defineStore('storeFiles', {
  state: () => {
    return {
      storageRef: ref(storage),
      files: [],
      loading: true
    }
  },
  actions: {
    listAll() {
      // Create a reference under which you want to list
      const listRef = ref(storage, '/');

      // Find all the prefixes and items.
      listAll(this.storageRef)
        .then((res) => {
          this.files = []
          res.prefixes.forEach((folderRef) => {
            // All the prefixes under listRef.
            // You may call listAll() recursively on them.
          });
          res.items.forEach((itemRef) => {
            getDownloadURL(itemRef)
              .then((url) => {
                getMetadata(itemRef)
                  .then((metadata) => {
                    itemRef['url'] = url
                    itemRef['metadata'] = metadata
                    this.files.push(itemRef)
                  })
              })
            // All the items under listRef.
          });
          this.loading = false
        }).catch((error) => {
          // Uh-oh, an error occurred!
        });
    },
    uploadFile(name, data) {
      const fileRef = ref(storage, name)
      uploadBytes(fileRef, data)
        .then((snapshot) => alert('done'))
        .catch((error) => alert(error.message))
    },
    deleteFile(fileRef) {
      deleteObject(fileRef)
        .then(() => alert('deleted'))
        .catch((error) => alert(error.message))
    },
    replaceVariables(str) {
      let result = `${str}`
      // replace variables for images
      const imgRegex = /\[(.*?)\]\(([0-9\,cfw]+)\)/g
      const matches = [...result.matchAll(imgRegex)]
      matches.forEach((match, index) => {
        const file = this.files.find(file => file.name === match[1])
        const params = match[2].split(',')
        if (file) {
          let style
          if (params.first() === 'fw') style = 'width:100%'
          if (!Number.isNaN(params.first())) {
            if (!Number.isNaN(params[1])) {
              style = `height:${params[0]}px;width:${params[1]}px`
            } else {
              style = `height:${params[0]}px`
            }
          }
          if (params.last() === 'c') {
            result = result.replace(match[0], `<div style="text-align:center"><img src="${file.url}" style="${style}"></div>`)
          } else {
            result = result.replace(match[0], `<img src="${file.url}" style="${style}">`)
          }
        }
      })
      return result
    },
  }
})
