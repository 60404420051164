import { v4 as uuidv4 } from 'uuid';

export class Round {
  constructor(number, poolSize) {
    this.id = uuidv4();
    this.number = number;
    this.ranking = 'poule'; // note: or 'group'
    this.placement = 'ss'; // note: sw: strong/weak or ss: strong/strong
    this.pouleSize = poolSize;
    this.groups = 1;
    this.groupSize = 2;
    this.matchDuration = undefined;
    this.betweenMatchDuration = undefined;
    this.after = undefined;
    this.knockout = false;
  }
}
