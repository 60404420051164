<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <img :src="logoImageUrl" class="logo">
      <div class="title mx-0 my-0">ISSTT {{ issttStore?.currentYear?.year }}</div>
      <Checkbox v-if="authStore.isAdmin" v-model="authStore.adminView" style="margin-left:1rem"/>
      <a
        role="button"
        class="navbar-burger is-hidden-desktop"
        aria-label="menu"
        aria-expanded="false"
        :class="{'is-active' : isActive}"
        @click.prevent="isActive = !isActive"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <NavigationMobileMenu :open="isActive" @close="isActive = false"><slot></slot></NavigationMobileMenu>
  </nav>

</template>

<script setup>
import { useStoreAuth } from '../../stores/storeAuth';
import { useStoreIsstt } from '@/stores/storeIsstt'
import { ref, watch } from 'vue'
import { getDownloadURL } from "firebase/storage";

import NavigationMobileMenu from '@/components/navigation/NavigationMobileMenu.vue';
import Checkbox from '@/components/generic/Checkbox.vue';

const authStore = useStoreAuth();

const issttStore = useStoreIsstt()
const emit = defineEmits(['loaded'])

const isActive = ref(false)

const logoImageUrl = ref('')
watch(
  () => issttStore.logoImageRef,
  (current, old) => {
    if (current) {
        let result
        getDownloadURL(current)
          .then((url) => {
            logoImageUrl.value = url
            emit('loaded')
          })
      } else {
        emit('loaded')
      }
  }
)
</script>

<style scoped>
.logo {
  height: 28px;
  margin: .5rem;
}
.navbar-brand {
  align-items: center;
}
.navbar {
  background-color: rgba(255,255,255,.97);
}
.navbar-menu {
  background: transparent;
}
</style>